.skeltonDiv{
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

@media(max-width:500px) {
    .skeltonDiv{
        /* width: 18rem; */
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        gap: 0px;
    }
    .react-loading-skeleton {
        width: 18rem !important;
    }
}