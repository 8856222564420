.Oright {
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.Orightt{
    width: 50%;
    /* height: 250px; */
    height: auto;
}

.newOMainBox {
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #EDEDED;
    border-radius: 52px;
    padding: 0px 0px;
    width: 130px;
    height: 270px;
    transition: transform .5s;
}
.newOMainBox:hover{
    /* background-color: #D00000; */
    background-color: #DA1F24;
    transform: scale(1.1);
    cursor: pointer;
    color: white !important;
}
.newOMainBox:hover .olymHover{
    display: block !important;
}
.newOMainBox:hover .olym{
    display: none !important;
}

.olymHover{display: none;}

.newOimgbox {
    width: 100px;
    height: 100px;
    /* border: 2px solid yellow; */
}

.newOimgbox img {
    width: 100%;
    height: 100%;
}

.newOboardheading h4{
    /* border: 2px solid yellow; */
    color: #1E1E1E;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
}

.newOboardheading h4:hover{
    color: white;
}

.newOMainBox:hover .newOboardheading h4 {
    color: white;
}

#cbseImg{
    /* border: 2px solid black; */
    /* width: 50%; */
    padding: 0px 5px;
}
#IGCSEImg{
    /* border: 2px solid black; */
    /* width: 60%; */
    padding: 8px 5px;
}

@media (max-width: 430px){
    #cbseImg{
        /* border: 2px solid black; */
        /* width: 50%; */
        padding: 0px 0px !important;
    }
    #IGCSEImg{
        /* border: 2px solid black; */
        /* width: 60%; */
        padding: 3px 0px;
    }
    .newOMainBox {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #EDEDED;
        border-radius: 22px;
        padding: 0px 0px;
        width: 60px;
        height: 120px;
        transition: transform .5s;
        margin-right: 5px;
    }
    .newOimgbox {
        width: 50px;
        height: 50px;
        /* border: 2px solid yellow; */
    }
    
    .newOimgbox img {
        width: 100%;
        height: 100%;
    }
    .newOboardheading h4{
        color: #1E1E1E;
        text-align: center;
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 10px;
    }
    /* .Oright {
        display: flex;
        justify-content:center;
        align-items: center;
    } */
    
}

@media (min-width:1200px) and (max-width:1400px){
    .newOMainBox {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #EDEDED;
        border-radius: 42px;
        padding: 0px 25px;
        width: 120px;
        height: 240px;
        transition: transform .5s;
    }
    .newOimgbox {
        width: 80px;
        height: 80px;
    }
}
@media (min-width:900px) and (max-width:1199px){
    .newOMainBox {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #EDEDED;
        border-radius: 22px;
        padding: 0px 25px;
        width: 120px;
        height: 240px;
        transition: transform .5s;
    }
    .newOimgbox {
        width: 90px;
        height: 90px;
        /* border: 2px solid yellow; */
    }
    .newOboardheading h4{
        color: #1E1E1E;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 10px;
    }
}
@media (min-width:700px) and (max-width:899px){
    .newOMainBox {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #EDEDED;
        border-radius: 22px;
        padding: 0px 25px;
        width: 110px;
        height: 180px;
        transition: transform .5s;
    }
    .newOimgbox {
        width: 50px;
        height: 50px;
        /* border: 2px solid yellow; */
    }
    .newOboardheading h4{
        color: #1E1E1E;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 20px;
    }
    .offerImgDiv {
        /* border: 2px solid yellow; */
        width: 100%;
        /* height: 330px !important; */
        height: 170px !important;
        margin-top: 0px;
    }
}

@media (min-width:431px) and (max-width:699px){
    .newOMainBox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #EDEDED;
        border-radius: 52px;
        padding: 0px 25px;
        width: 50px;
        height: 120px;
        transition: transform .5s;
    }
    .newOimgbox {
        width: 40px;
        height: 40px;
    }
    .newOboardheading h4{
        color: #1E1E1E;
        text-align: center;
        font-size: 6px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 20px;
    }
    .offerImgDiv {
        width: 100%;
        height: auto !important;
        margin-top: 100px;
    }
}