.medicalTopDiv {
    /* border: 2px solid yellow; */
    padding: 0px 150px;
}

.medicalMain {
    /* border: 2px solid black; */
    margin-top: 50px;
    display: flex;
    align-items: center;
}

.medicalLeftDiv {
    /* border: 2px solid red; */
    width: 1000px !important;
    height: 450px !important;
}

.medicalLeftDiv img {
    width: 100%;
    height: 100%;
}

.medicalRightDiv {
    /* border: 2px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.medicalRightDiv h3 {
    color: #D00000;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
}

.medicalRightDiv p {
    color: #000;
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
    line-height: 34.5px;
}

.medicalSeconDiv {
    margin-top: 50px;
}

.medicalSeconDiv h1 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.medicalSeconMainDiv {
    /* border: 2px solid green; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.medicalMultipleText {
    /* border: 2px solid black; */
    width: 500px;
    height: 160px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.medicalMultipleInnerDiv {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
}

.medicalMultipleInnerDiv img {
    width: 4%;
    height: 100%;
    margin-right: 10px;
}

.medicalMultipleInnerDiv h3 {
    color: #D00000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.36px;
    margin-top: 8px;
}

.medicalMultipleText p {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
    text-align: justify;
}

.medicalThirdDiv h1 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.medicalTipsDiv {
    /* border: 2px solid red; */
    margin: 30px 0px;
    display: flex;
    width: 530px;
    height: 190px;
}

.medicaltipsImgDiv {
    /* border: 2px solid green; */
    /* width: 350px; */
    height: 120px;
    margin-right: 20px;
}

.medicalTipsDiv img {
    /* width: 100%; */
    height: 100%;
}

.medicaltipsInner {
    /* border: 2px solid black;  */
}

.medicaltipsInner h3 {
    color: #D00000;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.36px;
}

.medicaltipsInner p {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
}

.medicalMainTips {
    /* border: 2px solid yellow; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.medicalfourthTips {
    background-color: #D00000;
    padding-top: 40px;
}

.medicalfourthInner {
    /* border: 2px solid black; */
    padding: 0px 150px;
}

.medicalfourthInner h3 {
    color: #FFF;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 1.08px;
    text-transform: uppercase;
    /* margin-top: 50px; */
}

.medicalFourthRed {
    /* border: 2px solid green; */
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.redBoxxImgdiv {
    /* border: 2px solid yellow; */
    width: 200px;
    height: 200px;
}

.redBoxxImgdiv img {
    width: 100%;
    height: 100%;
}

.redBoxxImghead {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
}

.medicalFourthMain {
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.medicalLastDiv {
    padding: 20px 150px;
}

.medicalFaq {
    color: #000;
    font-size: 35.898px;
    font-weight: 400;
}

@media (max-width:600px) {
    .medicalTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 20px;
    }

    .medicalMain {
        /* border: 2px solid black; */
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .medicalLeftDiv {
        width: 100% !important;
        height: 100% !important;
    }

    .medicalRightDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    .medicalRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 12px;
        font-weight: 400;
        line-height: 24.5px;
    }

    .medicalRightDiv {
        /* border: 2px solid green; */
        margin-top: 20px;
        padding-left: 0px;
    }

    .medicalSeconDiv {
        margin-top: 50px;
        /* border: 2px solid green; */
    }

    .medicalSeconDiv h1 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .medicalSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .medicalMultipleText {
        /* border: 2px solid black; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .medicalMultipleInnerDiv {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .medicalMultipleInnerDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .medicalMultipleText p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }

    .medicalThirdDiv h1 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .medicalTipsDiv {
        /* border: 2px solid red; */
        margin: 30px px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 530px;
        height: 190px;
    }

    .medicaltipsImgDiv {
        /* border: 2px solid green; */
        /* width: 350px; */
        height: 80px;
        margin-right: 20px;
    }

    .medicaltipsInner h3 {
        color: #D00000;
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
        font-weight: 700;
        letter-spacing: -0.36px;
    }

    .medicaltipsInner p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        letter-spacing: -0.24px;
    }

    .medicalLastDiv {
        padding: 20px 10px;
    }

    .medicalFaq {
        color: #000;
        font-size: 22.898px;
        font-weight: 400;
        margin-left: 10px;
    }

    .accordion__button {
        background-color: #f4f4f4;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
        font-size: 11px;
    }

    .accordion__panel {
        font-size: 11px;
        padding: 20px px;
        animation: fadein 0.35s ease-in;
    }

    .medicalfourthTips {
        background-color: #D00000;
        padding-top: 40px;
    }

    .medicalfourthInner {
        /* border: 2px solid black; */
        padding: 0px 10px;
    }

    .medicalFourthMain {
        /* border: 2px solid black; */
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        flex-direction: column;
    }

    .medicalfourthInner h3 {
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        text-align: center;
        /* margin-top: 50px; */
    }

    .redBoxxImgdiv {
        /* border: 2px solid yellow; */
        width: 150px;
        height: 150px;
    }

    .redBoxxImghead {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
    }

    .medicalFourthRed {
        /* border: 2px solid green; */
        width: 270px;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media (min-width:1800px) {
    .medicalSeconDiv {
        margin-top: 50px;
        /* border: 2px solid black; */
    }

    .medicalSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .medicalMultipleText {
        /* border: 2px solid red; */
        width: 450px;
        height: 160px;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .medicalTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 200px;
    }

    .medicalLastDiv {
        padding: 20px 200px;
    }

    .medicalMultipleText p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: justify;
    }
}


@media (min-width: 601px) and (max-width: 1000px) {
    .medicalTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 50px;
    }

    .medicalMain {
        /* border: 2px solid black; */
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .medicalLeftDiv {
        /* border: 2px solid red; */
        width: auto !important;
        height: auto !important;
    }

    .medicalRightDiv h3 {
        color: #D00000;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin: 20px 0px;
        text-transform: uppercase;
    }

    .medicalRightDiv {
        /* border: 2px solid green; */
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0px;
    }
}


@media (min-width:1001px)and (max-width:1396px) {
    .medicalTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 50px;
    }


    .medicalMultipleText {
        /* border: 2px solid black; */
        width: 430px;
        height: 160px;
        margin-right: 0px;
        margin-bottom: 20px;
    }
}