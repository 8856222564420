.centerBanner {
  width: 80%;
  height: auto;
  border-radius: 15px;
  background-color: #f9f9f9;
  margin: 10px auto 100px auto;
  padding-bottom: 40px;
}

.outCenterHeading {
  display: flex;
  justify-content: center;
}

.outCenterHeading2 {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  font-size: 48px;
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.business-card {
  background-color: white;
  border-radius: 15px;
  /* border: 1px solid black; */
  width: 30%;
  margin: 18px;
}

.business-cardHead {
  background-color: #D00000;
  border-radius: 15px
}

.contatcInfoName {
  padding: 20px;
  font-weight: 600;
  font-size: 20px;
  color: white;
}

.contactPhone {
  padding: 15px;
  margin-bottom: 0rem;
}

.businessCollection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.mail {
  width: 15px;
  margin-right: 10px;
}

.locationAdd {
  padding: 15px;
  display: flex;
  align-items: baseline;
}

@media(max-width:600px) {
  .business-card {
    background-color: white;
    border-radius: 12px;
    /* border: 1px solid black; */
    width: 100%;
    margin: 18px;
  }

  .outCenterHeading2 {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    font-size: 31px;
    font-weight: 800;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .centerBanner {
    width: 100%;
    height: auto;
    border-radius: 15px;
    background-color: #f9f9f9;
    margin: 80px auto 100px auto;
    padding-bottom: 40px;
  }
}


@media (min-width: 601px) and (max-width: 1200px) {
  .centerBanner {
    width: 100%;
    height: auto;
    border-radius: 15px;
    background-color: #f9f9f9;
    margin: 10px auto 100px auto;
    padding-bottom: 40px;
  }

  .business-card {
    background-color: white;
    border-radius: 15px;
    /* border: 1px solid black; */
    width: 40%;
    margin: 18px;
  }
}