.performanceTopDiv {
    /* border: 2px solid black; */
    padding: 0px 150px;
}

.perforribbin {
    background-color: #D00000;
    margin-top: 50px;
    padding: 30px;
}

.perforribbin h3 {
    color: white;
    text-align: center;
    margin: 0px;
    font-size: 30px;
    font-weight: 600;
}

.performanceMain {
    /* border: 2px solid black; */
    margin-top: 60px;
    display: flex;
    align-items: center;
}

.performanceLeftDiv {
    /* border: 2px solid red; */
    width: 1000px !important;
    height: 450px !important;
}

.performanceLeftDiv img {
    width: 100%;
    height: 100%;
}

.performanceRightDiv {
    /* border: 2px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.performanceRightDiv h3 {
    color: #D00000;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
}

.performanceRightDiv p {
    color: #000;
    text-align: justify;
    font-size: 17px;
    font-weight: 600;
    line-height: 30.5px;
}

.performanceSeconDiv {
    /* margin-top: 50px; */
    margin: 50px 0px;
}

.performanceSeconDiv h1 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.performanceSeconMainDiv {
    /* border: 2px solid green; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.performanceMultipleText {
    /* border: 2px solid black; */
    width: 100%;
    /* width: 500px; */
    /* height: 160px; */
    height: auto;
    margin-right: 0px;
    margin-bottom: 20px;
}

.performanceMultipleInnerDiv {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
}

.performanceMultipleInnerDiv img {
    width: 4%;
    height: 100%;
    margin-right: 10px;
}

.performanceMultipleInnerDiv h3 {
    color: #D00000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.36px;
    margin-top: 8px;
}

.performanceMultipleText p {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
}

.performanceThirdDiv h1 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.performanceTipsDiv {
    /* border: 2px solid red; */
    margin: 30px 0px;
    display: flex;
    width: 530px;
    height: 190px;
}

.performancetipsImgDiv {
    /* border: 2px solid green; */
    /* width: 350px; */
    height: 120px;
    margin-right: 20px;
}

.performanceTipsDiv img {
    /* width: 100%; */
    height: 100%;
}

.performancetipsInner {
    /* border: 2px solid black;  */
}

.performancetipsInner h3 {
    color: #D00000;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.36px;
}

.performancetipsInner p {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
}

.performanceMainTips {
    /* border: 2px solid yellow; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.performancefourthTips {
    background-color: #D00000;
    padding-top: 40px;
}

.performancefourthInner {
    /* border: 2px solid black; */
    padding: 0px 150px;
}

.performancefourthInner h3 {
    color: #FFF;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 1.08px;
    text-transform: uppercase;
    /* margin-top: 50px; */
}

.performanceFourthRed {
    /* border: 2px solid green; */
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.redBoxxImgdiv {
    /* border: 2px solid yellow; */
    width: 200px;
    height: 200px;
}

.redBoxxImgdiv img {
    width: 100%;
    height: 100%;
}

.redBoxxImghead {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
}

.performanceFourthMain {
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.performanceLastDiv {
    padding: 20px 150px;
}

.performanceFaq {
    color: #000;
    font-size: 35.898px;
    font-weight: 400;
}

@media (max-width:600px) {
    .performanceTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 20px;
    }

    .performanceMain {
        /* border: 2px solid black; */
        margin-top: 35px;
        display: flex;
        flex-direction: column;
    }

    .performanceLeftDiv {
        width: 100% !important;
        height: 100% !important;
    }

    .performanceRightDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    .performanceRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 12px;
        font-weight: 400;
        line-height: 24.5px;
    }

    .performanceRightDiv {
        /* border: 2px solid green; */
        margin-top: 20px;
        padding-left: 0px;
    }

    .performanceSeconDiv {
        margin-top: 25px;
        /* border: 2px solid green; */
    }

    .performanceSeconDiv h1 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .performanceSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .performanceMultipleText {
        /* border: 2px solid black; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .performanceMultipleInnerDiv {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .performanceMultipleInnerDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .performanceMultipleText p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: left;
    }

    .performanceThirdDiv h1 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .performanceTipsDiv {
        /* border: 2px solid red; */
        margin: 30px px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 530px;
        height: 190px;
    }

    .performancetipsImgDiv {
        /* border: 2px solid green; */
        /* width: 350px; */
        height: 80px;
        margin-right: 20px;
    }

    .performancetipsInner h3 {
        color: #D00000;
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
        font-weight: 700;
        letter-spacing: -0.36px;
    }

    .performancetipsInner p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        letter-spacing: -0.24px;
    }

    .performanceLastDiv {
        padding: 20px 10px;
    }

    .performanceFaq {
        color: #000;
        font-size: 22.898px;
        font-weight: 400;
        margin-left: 10px;
    }

    .accordion__button {
        background-color: #f4f4f4;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
        font-size: 10px;
    }

    .accordion__panel {
        font-size: 10px;
        padding: 20px px;
        animation: fadein 0.35s ease-in;
    }

    .performancefourthTips {
        background-color: #D00000;
        padding-top: 40px;
    }

    .performancefourthInner {
        /* border: 2px solid black; */
        padding: 0px 10px;
    }

    .performanceFourthMain {
        /* border: 2px solid black; */
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        flex-direction: column;
    }

    .performancefourthInner h3 {
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        text-align: center;
        /* margin-top: 50px; */
    }

    .redBoxxImgdiv {
        /* border: 2px solid yellow; */
        width: 150px;
        height: 150px;
    }

    .redBoxxImghead {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
    }

    .performanceFourthRed {
        /* border: 2px solid green; */
        width: 270px;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .perforribbin {
        background-color: #D00000;
        margin-top: 20px;
        padding: 12px;
    }

    .perforribbin h3 {
        color: white;
        text-align: center;
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
    }
}

@media (min-width: 601px) and (max-width: 1000px) {
    .performanceTopDiv {
        /* border: 2px solid black; */
        padding: 0px 50px;
    }

    .performanceMain {
        /* border: 2px solid black; */
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .performanceLeftDiv {
        width: auto !important;
        height: 450px !important;
    }
    .performanceRightDiv h3 {
        color: #D00000;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin: 30px 0px;
        text-transform: uppercase;
    }
    .perforribbin h3 {
        color: white;
        text-align: center;
        margin: 0px;
        font-size: 25px;
        font-weight: 600;
    }
}



@media (min-width: 1001px) and (max-width: 1300px) {
    .performanceTopDiv {
        /* border: 2px solid black; */
        padding: 0px 50px;
    }
    .performanceRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 13px;
        font-weight: 600;
        line-height: 25.5px;
    }
}



@media (min-width:1800px) {
    .performanceSeconDiv {
        margin-top: 50px;
        /* border: 2px solid black; */
    }

    .performanceSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .performanceMultipleText {
        /* border: 2px solid red; */
        /* width: 450px; */
        /* height: 160px; */
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .performanceTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 200px;
    }
}