.preschoolTopDiv {
    /* border: 2px solid yellow; */
    padding: 0px 150px;
}

.preschoolMain {
    /* border: 2px solid black; */
    margin-top: 50px;
    display: flex;
    align-items: center;
}

.preschoolLeftDiv {
    /* border: 2px solid green; */
    width: 1000px !important;
    height: 450px !important;
}

.preschoolLeftDiv img {
    width: 100%;
    height: 100%;

}

.preschoolRightDiv {
    /* border: 2px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.preschoolRightDiv h3 {
    color: #D00000;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
}

.preschoolRightDiv p {
    color: #000;
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
    line-height: 34.5px;
}

.preschoolSecondDiv {
    /* border: 2px solid black; */
    background-color: #D00000;
    padding: 50px 150px;
    margin-top: 50px;
}

.preschoolSeconDiv h1 {
    color: white;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.preschoolSeconMainDiv {
    /* border: 2px solid green; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.preschoolMultipleText {
    /* border: 2px solid black; */
    width: 500px;
    height: 160px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.preschoolMultipleInnerDiv {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
}

.preschoolMultipleInnerDiv img {
    width: 4%;
    height: 100%;
    margin-right: 10px;
}

.preschoolMultipleInnerDiv h3 {
    color: white;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.36px;
    margin-top: 8px;
}

.preschoolMultipleText p {
    color: white;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
}

.preschoolThirdDiv {
    /* border: 2px solid black; */
    padding: 50px 150px;
}

.preschoolMainDiv2 {
    /* border: 2px solid black; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.preschoolMultipleText2 {
    /* border: 2px solid black; */
    width: 500px;
    /* height: 180px; */
    height: auto;
    margin-right: 0px;
    margin-bottom: 20px;
}

#matrixBoxx {
    /* border: 2px solid green; */
    width: 500px;
    height: 260px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.preschoolMultipleInnerDiv2 {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
}

.preschoolMultipleInnerDiv2 img {
    width: 4%;
    height: 100%;
    margin-right: 10px;
}

.preschoolMultipleInnerDiv2 h3 {
    color: #D00000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.36px;
    margin-top: 8px;
}

.preschoolMultipleText2 p {
    color: black;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
}

.preschoolThirdDiv h1 {
    color: black;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.matrixDiv {
    /* border: 2px solid green; */
    width: 200px;
    height: 200px;
}

.matrixDiv img {
    width: 100%;
    height: 100%;
}

.preschoolLastDiv {
    padding: 20px 150px;
}

.preschoolFaq {
    color: #000;
    font-size: 35.898px;
    font-weight: 400;
}

.preschoolTriviaImgDiv {
    /* border: 2px solid black; */
    margin-bottom: 50px;
    width: 100%;
    height: auto;
}

.preschoolTriviaImgDiv img {
    width: 100%;
    height: 100%;
}

.preschoolInnerDivv {
    /* border: 2px solid green; */
}

.preschoolflexDiv {
    /* border: 2px solid blue; */
    display: flex;
    margin-bottom: 20px;
}

.preschoolflexDiv2 {
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
}

.preschoolflexDiv2 h3 {
    text-align: right;
}

.preschoolflexDiv2 p {
    text-align: right;
}

.preschoolFlexheadingdiv h3 {
    color: white;
    font-size: 24px;
    font-weight: 700;
}

.preschoolFlexheadingdiv p {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.preschoolFlexImgdiv {
    /* border: 2px solid yellow; */
    width: 320px;
    height: 200px;
    margin-right: 30px;
}

.preschoolFlexImgdiv img {
    width: 100%;
    height: 100%;
    border-radius: 50% !important;

}

#preschoolLastImg {
    /* border: 2px solid green; */
    width: 690px;
    height: 200px;
    margin-right: 30px;
}

#preschooseconlLastImg {
    /* border: 2px solid green; */
    width: 380px;
    height: 200px;
    margin-right: 0px;
    margin-left: 30px;
}

.preshoollastBigimgDiv {
    /* border: 2px solid black; */
    margin-top: -50px;
}





@media (max-width:600px) {
    .preschoolTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 20px;
    }

    .preschoolMain {
        /* border: 2px solid black; */
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .preschoolLeftDiv {
        width: 100% !important;
        height: 100% !important;
    }

    .preschoolRightDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    .preschoolRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 12px;
        font-weight: 400;
        line-height: 24.5px;
    }

    .preschoolRightDiv {
        /* border: 2px solid green; */
        margin-top: 20px;
        padding-left: 0px;
    }

    .preschoolSecondDiv {
        /* border: 2px solid black; */
        background-color: #D00000;
        padding: 50px 21px;
        margin-top: 50px;
    }

    .preschoolSeconDiv h1 {
        color: white;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .preschoolSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .preschoolMultipleText {
        /* border: 2px solid black; */
        width: 500px;
        height: 100px;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .preschoolMultipleInnerDiv {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .preschoolMultipleInnerDiv h3 {
        color: white;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .preschoolMultipleText p {
        color: white;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }

    .preschoolLastDiv {
        padding: 20px 10px;
    }

    .preschoolFaq {
        color: #000;
        font-size: 22.898px;
        font-weight: 400;
        margin-left: 10px;
    }

    .preschoolThirdDiv {
        /* border: 2px solid black; */
        padding: 50px 20px;
    }

    .preschoolThirdDiv h1 {
        color: black;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .preschoolMultipleText2 {
        /* border: 2px solid black; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .preschoolMultipleInnerDiv2 {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    #preschoolFirst {
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 0px;
        /* border: 2px solid red; */
    }

    .preschoolMultipleInnerDiv2 h3 {
        color: #D00000;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .preschoolMultipleText2 p {
        color: black;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }

    #matrixBoxx {
        /* border: 2px solid green; */
        width: 500px;
        height: 260px !important;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .preschoolTriviaImgDiv {
        /* border: 2px solid black; */
        margin-bottom: 0px;
        width: 100%;
        height: auto;
    }

    .preschoolTriviaImgDiv img {
        width: 100%;
        height: 100%;
    }

    #icseFirst {
        /* border: 2px solid black ; */
        width: 500px;
        height: 300px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .preschoolflexDiv {
        /* border: 2px solid blue; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .preschoolFlexImgdiv {
        /* border: 2px solid yellow; */
        width: 170px;
        height: 170px;
        margin-right: 0px;
    }

    .preschoolFlexheadingdiv h3 {
        color: white;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }

    .preschoolFlexheadingdiv p {
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    .preschoolFlexheadingdiv {
        margin-top: 20px;
    }

    .preschoolflexDiv2 {
        /* border: 2px solid blue; */
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
    }

    #preschooseconlLastImg {
        /* border: 2px solid green; */
        width: 170px;
        height: 170px;
        margin-right: 0px;
        margin-left: 0px;
    }

    #preschoolLastImg {
        /* border: 2px solid green;  */
        width: 170px;
        height: 170px;
        margin-right: 0px;
    }

    .preshoollastBigimgDiv {
        /* border: 2px solid black; */
        width: 100%;
        margin-top: 0px;
    }

    .preshoollastBigimgDiv img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width:1800px) {
    .preschoolSeconDiv {
        margin-top: 50px;
        /* border: 2px solid black; */
    }

    .preschoolSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .preschoolMultipleText {
        /* border: 2px solid red; */
        width: 450px;
        height: 160px;
        margin-right: 50px;
        margin-bottom: 20px;
    }

    .preschoolMainDiv2 {
        /* border: 2px solid black; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .preschoolMultipleText2 {
        /* border: 2px solid black; */
        width: 400px;
        /* height: 220px; */
        height: auto;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .preschoolFlexImgdiv {
        /* border: 2px solid yellow; */
        width: 20%;
        height: 100%;
        /* margin-right: 30px; */
    }

    #preschooseconlLastImg {
        /* border: 2px solid green; */
        width: 22%;
        height: 100%;
        margin-right: 0px;
        margin-left: 30px;
    }

    #preschoolLastImg {
        /* border: 2px solid green; */
        width: 42%;
        height: 100%;
        margin-right: 30px;
    }

    .preschoolTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 200px;
    }

    .preschoolSecondDiv {
        /* border: 2px solid black; */
        background-color: #D00000;
        padding: 50px 200px;
        margin-top: 50px;
    }

    .preschoolThirdDiv {
        /* border: 2px solid black; */
        padding: 50px 200px;
    }
}

@media (min-width:601px)and (max-width:1000px) {
    .preschoolTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 20px;
    }

    .preschoolMain {
        /* border: 2px solid black; */
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .preschoolLeftDiv {
        width: 100% !important;
        height: 100% !important;
    }

    .preschoolRightDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    .preschoolRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 12px;
        font-weight: 400;
        line-height: 24.5px;
    }

    .preschoolRightDiv {
        /* border: 2px solid green; */
        margin-top: 20px;
        padding-left: 0px;
    }

    .preschoolSecondDiv {
        /* border: 2px solid black; */
        background-color: #D00000;
        padding: 50px 21px;
        margin-top: 50px;
    }

    .preschoolSeconDiv h1 {
        color: white;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .preschoolSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .preschoolMultipleText {
        /* border: 2px solid black; */
        width: 500px;
        height: 100px;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .preschoolMultipleInnerDiv {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .preschoolMultipleInnerDiv h3 {
        color: white;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .preschoolMultipleText p {
        color: white;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }

    .preschoolLastDiv {
        padding: 20px 10px;
    }

    .preschoolFaq {
        color: #000;
        font-size: 22.898px;
        font-weight: 400;
        margin-left: 10px;
    }

    .preschoolThirdDiv {
        /* border: 2px solid black; */
        padding: 50px 20px;
    }

    .preschoolThirdDiv h1 {
        color: black;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .preschoolMultipleText2 {
        /* border: 2px solid black; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .preschoolMultipleInnerDiv2 {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    #preschoolFirst {
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 0px;
        /* border: 2px solid red; */
    }

    .preschoolMultipleInnerDiv2 h3 {
        color: #D00000;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .preschoolMultipleText2 p {
        color: black;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }

    #matrixBoxx {
        /* border: 2px solid green; */
        width: 500px;
        height: 260px !important;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .preschoolTriviaImgDiv {
        /* border: 2px solid black; */
        margin-bottom: 0px;
        width: 100%;
        height: auto;
    }

    .preschoolTriviaImgDiv img {
        width: 100%;
        height: 100%;
    }

    #icseFirst {
        /* border: 2px solid black ; */
        width: 500px;
        height: 300px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .preschoolflexDiv {
        /* border: 2px solid blue; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .preschoolFlexImgdiv {
        /* border: 2px solid yellow; */
        width: 170px;
        height: 170px;
        margin-right: 0px;
    }

    .preschoolFlexheadingdiv h3 {
        color: white;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }

    .preschoolFlexheadingdiv p {
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    .preschoolFlexheadingdiv {
        margin-top: 20px;
    }

    .preschoolflexDiv2 {
        /* border: 2px solid blue; */
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
    }

    #preschooseconlLastImg {
        /* border: 2px solid green; */
        width: 170px;
        height: 170px;
        margin-right: 0px;
        margin-left: 0px;
    }

    #preschoolLastImg {
        /* border: 2px solid green;  */
        width: 170px;
        height: 170px;
        margin-right: 0px;
    }

    .preshoollastBigimgDiv {
        /* border: 2px solid black; */
        width: 100%;
        margin-top: 0px;
    }

    .preshoollastBigimgDiv img {
        width: 100%;
        height: 100%;
    }

    .preschoolMainDiv2 {
        /* border: 2px solid black; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}


@media (min-width:1001px)and (max-width:1396px) {

    .preschoolTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 50px;
    }

    .preschoolRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 15px;
        font-weight: 400;
        line-height: 25.5px;
    }

    .preschoolflexDiv {
        /* border: 2px solid blue; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .preschoolflexDiv2 {
        /* border: 2px solid blue; */
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
    }
    .preschoolFlexImgdiv {
        /* border: 2px solid yellow; */
        width: 170px;
        height: 170px;
        margin-right: 0px;
    }
    #preschooseconlLastImg {
        /* border: 2px solid green; */
        width: 170px;
        height: 170px;
        margin-right: 0px;
        margin-left: 0px;
    }
    #preschoolLastImg {
        /* border: 2px solid green; */
        width: 170px;
        height: 170px;
        margin-right: 0px;
    }
    .preschoolFlexheadingdiv h3 {
        color: white;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }
    .preschoolFlexheadingdiv p {
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
    .preschoolSeconDiv h1 {
        color: white;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }
}