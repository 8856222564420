.tybcomTopDiv {
    /* border: 2px solid yellow; */
    padding: 0px 150px;
}

.tybcomMain {
    /* border: 2px solid black; */
    margin-top: 50px;
    display: flex;
    align-items: center;
}

.tybcomLeftDiv {
    /* border: 2px solid red; */
    width: 1000px !important;
    height: 450px !important;
}

.tybcomLeftDiv img {
    width: 100%;
    height: 100%;
}

.tybcomRightDiv {
    /* border: 2px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.tybcomRightDiv h3 {
    color: #D00000;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
}

.tybcomRightDiv p {
    color: #000;
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
    line-height: 34.5px;
}

.tybcomSeconDiv {
    margin-top: 50px;
}

.tybcomSeconDiv h1 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.tybcomSeconMainDiv {
    /* border: 2px solid green; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.tybcomMultipleText {
    /* border: 2px solid black; */
    width: 500px;
    height: 160px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.tybcomMultipleInnerDiv {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
}

.tybcomMultipleInnerDiv img {
    width: 4%;
    height: 100%;
    margin-right: 10px;
}

.tybcomMultipleInnerDiv h3 {
    color: #D00000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.36px;
    margin-top: 8px;
}

.tybcomMultipleText p {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
}

.tybcomThirdDiv h1 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.tybcomTipsDiv {
    /* border: 2px solid red; */
    margin: 30px 0px;
    display: flex;
    width: 530px;
    height: 190px;
}

.tybcomtipsImgDiv {
    /* border: 2px solid green; */
    /* width: 350px; */
    height: 120px;
    margin-right: 20px;
}

.tybcomTipsDiv img {
    /* width: 100%; */
    height: 100%;
    max-width: none;
}

.tybcomtipsInner {
    /* border: 2px solid black;  */
}

.tybcomtipsInner h3 {
    color: #D00000;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.36px;
}

.tybcomtipsInner p {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
    text-align: justify;
}

.tybcomMainTips {
    /* border: 2px solid yellow; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tybcomfourthTips {
    background-color: #D00000;
    padding-top: 40px;
}

.tybcomfourthInner {
    /* border: 2px solid black; */
    padding: 0px 150px;
}

.tybcomfourthInner h3 {
    color: #FFF;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 1.08px;
    text-transform: uppercase;
    /* margin-top: 50px; */
}

.tybcomFourthRed {
    /* border: 2px solid green; */
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.redBoxxImgdiv {
    /* border: 2px solid yellow; */
    width: 200px;
    height: 200px;
}

.redBoxxImgdiv img {
    width: 100%;
    height: 100%;
}

.redBoxxImghead {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
}

.tybcomFourthMain {
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.tybcomLastDiv {
    padding: 20px 150px;
}

.tybcomFaq {
    color: #000;
    font-size: 35.898px;
    font-weight: 400;
}

.visibleTY{
    display: none;
    visibility: hidden;
}

@media (max-width:600px) {
    .visibleTY{
        display: none !important;
        visibility: hidden;
    }
    .tybcomTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 20px;
    }

    .tybcomMain {
        /* border: 2px solid black; */
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .tybcomLeftDiv {
        width: 100% !important;
        height: 100% !important;
    }

    .tybcomRightDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    .tybcomRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 12px;
        font-weight: 400;
        line-height: 24.5px;
    }

    .tybcomRightDiv {
        /* border: 2px solid green; */
        margin-top: 20px;
        padding-left: 0px;
    }

    .tybcomSeconDiv {
        margin-top: 50px;
        /* border: 2px solid green; */
    }

    .tybcomSeconDiv h1 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .tybcomSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .tybcomMultipleText {
        /* border: 2px solid black; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .tybcomMultipleInnerDiv {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .tybcomMultipleInnerDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .tybcomMultipleText p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }

    .tybcomThirdDiv h1 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .tybcomTipsDiv {
        /* border: 2px solid red; */
        margin: 30px  0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 530px;
        height: auto;
    }

    .tybcomtipsImgDiv {
        /* border: 2px solid green; */
        /* width: 350px; */
        height: 80px;
        margin-right: 20px;
    }

    .tybcomtipsInner h3 {
        color: #D00000;
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
        font-weight: 700;
        letter-spacing: -0.36px;
    }

    .tybcomtipsInner p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        letter-spacing: -0.24px;
    }

    .tybcomLastDiv {
        padding: 20px 10px;
    }

    .tybcomFaq {
        color: #000;
        font-size: 22.898px;
        font-weight: 400;
        margin-left: 10px;
    }

    .accordion__button {
        background-color: #f4f4f4;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
        font-size: 10px;
    }

    .accordion__panel {
        font-size: 10px;
        padding: 20px px;
        animation: fadein 0.35s ease-in;
    }

    .tybcomfourthTips {
        background-color: #D00000;
        padding-top: 40px;
    }
    
    .tybcomfourthInner {
        /* border: 2px solid black; */
        padding: 0px 10px;
    }
    .tybcomFourthMain {
        /* border: 2px solid black; */
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        flex-direction: column;
    }
    .tybcomfourthInner h3 {
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        text-align: center;
        /* margin-top: 50px; */
    }
    .redBoxxImgdiv {
        /* border: 2px solid yellow; */
        width: 150px;
        height: 150px;
    }
    .redBoxxImghead {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
    }
    .tybcomFourthRed {
        /* border: 2px solid green; */
        width: 270px;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media (min-width:1800px){
    .tybcomSeconDiv {
        margin-top: 50px;
        /* border: 2px solid black; */
    }
    .tybcomSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .tybcomMultipleText {
        /* border: 2px solid red; */
        width: 450px;
        height: 160px;
        margin-right: 50px;
        margin-bottom: 20px;
    }
    .tybcomTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 200px;
    }
    .tybcomTipsDiv {
        /* border: 2px solid red; */
        margin: 30px 0px;
        display: flex;
        width: 503px;
        /* height: 190px; */
        height: auto;
    }
    .tybcomLastDiv {
        padding: 20px 200px;
    }
    .visibleTY{
        display: block;
        visibility: hidden;
    }
}


@media (min-width: 601px) and (max-width: 1000px) {
    .tybcomTopDiv {
        /* border: 2px solid black; */
        padding: 0px 50px;
    }

    .tybcomMain {
        /* border: 2px solid black; */
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tybcomLeftDiv {
        width: auto !important;
        height: 450px !important;
    }
    .tybcomRightDiv h3 {
        color: #D00000;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin: 30px 0px;
        text-transform: uppercase;
    }
    .perforribbin h3 {
        color: white;
        text-align: center;
        margin: 0px;
        font-size: 25px;
        font-weight: 600;
    }
    .tybcomMultipleText {
        /* border: 2px solid black; */
        width: 620px;
        height: 160px;
        margin-right: 50px;
        margin-bottom: 20px;
    }
}



@media (min-width:1001px)and (max-width:1396px) {
    .tybcomTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 50px;
    }
    .tybcomMultipleText {
        /* border: 2px solid black; */
        width: 420px;
        height: 160px;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .tybcomTipsDiv {
        margin: 30px 0px;
        display: flex;
        width: 400px !important;
        height: 250px;
    }
    .visibleTY {
        display: none !important;
    }
}