.career {
    margin-top: 0px;
    height: auto;
    display: flex;
    background-color: #F7F7F7;
    align-items: center;
    justify-content: space-between;
}

.girlImg {
    /* width: 500px; */
    width: auto;
}

.girlImg2 {
    width: 55%;
}

.careerHeading {
    color: #D00000;
    font-weight: 700;
}

.careerText {
    width: 80%;
    font-weight: 600;
}

.careerTag {
    background-color: #D00000;
    padding: 50px;
    display: flex;
    justify-content: center;
}

.careerTag2 {
    color: white;
    text-align: center;
    line-height: 46px;
    font-family: 'Tangerine', cursive;
    font-size: 45px;
}

.jobForm {
    background-color: #D00000;

}

.jobHeading {
    display: flex;
    justify-content: center;
    color: white;
    padding: 40px 0px;
}

.jobFormSubmit {
    padding-bottom: 30px;
    width: 70%;
    margin: auto;
}

.enterFullName {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-bottom: 0px !important;
}

.entermail {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin: 20px 0px 0px 0px;
}

.entermail2 {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin: 15px 0px 0px 0px;
    display: flex;
    justify-content: end;
}


.mailNumber {
    display: flex;
    align-items: center;
    gap: 30px;

}

.sendButton {
    border: none;
    padding: 10px 60px;
    border-radius: 17px;
    color: #D00000;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.opportunities {
    background-color: #D9D9D9;
    /* height: 90vh; */
    height: auto;
}

.currentOpening {
    text-align: center;
    padding: 50px 0px;
}

.currentOpeninghEADING {
    font-weight: 700;
}

.currentOpening2 {
    width: 50%;
    margin: auto;
    padding: 12px;
    display: flex;
    justify-content: space-between;
}

.openingTeacher {
    margin-bottom: 0px;
    color: #D00000;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
}

.openingDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: end;
}

.openingDetails a {
    color: #D00000 !important;
}

.submitOpeing {
    border: none;
    border-radius: 21px;
    padding: 8px 28px;
    background-color: #D00000;
    color: white;
    text-transform: uppercase;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.headingDiv2 {
    display: flex;
    justify-content: center;
    padding: 30px 0px;

}

.headingDiv2 h1 {
    font-weight: 700;
    color: #D00000;
}

.hrline {
    max-width: 100% !important;
}


@media(max-width:480px) {
    .career {
        flex-direction: column;
        margin-top: 50px;
    }

    .girlImg {
        width: 100%;
    }

    .girlMainImage {
        width: 100%;
    }

    .careerText {
        width: 100%;
        font-weight: 600;
    }

    .girlImg2 {
        width: 100%;
        padding: 30px;
    }

    .mailNumber {
        display: flex;
        gap: 00px;
        flex-direction: column;
    }

    .currentOpening2 {
        width: 100%;
        margin: auto;
        padding: 20px;
    }

    .entermail2 {
        width: 100%;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        border: none;
        margin: 20px 0px 0px 0px;
        display: flex;
        justify-content: center;
    }

    .headingDiv2 {
        display: flex;
        justify-content: center;
        padding: 30px 0px 0px 0px;
    }

    .openingTeacher {
        margin-bottom: 0px;
        color: #D00000;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
    }
}



/* flips */

.aboutBackground {
    /* background: url('../../Assets/about/aboutus_web.png'); */
    background: url('../../Assets/about/anout\ us-web.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}



.FirstheadingDiv {
    background-color: #D00000;
    text-align: center;
    padding: 10px 0px;
    color: white;
    text-transform: uppercase;
    margin-top: 80px;
}

.FirstheadingDiv h1 {
    font-size: 30px !important;
    margin-top: 8px;
}



.psgDiv {
    /* border: 2px solid black; */
    padding: 80px;
    width: 80%;
    margin: auto;
    text-align: center;
    font-weight: 600;
}

.psgDivCareer {
    /* border: 2px solid black; */
    padding: 0px;
    width: 80%;
    margin: auto;
    text-align: center;
    font-weight: 600;
}

.psgDiv2 {
    /* border: 2px solid black; */
    padding: 0px 120px;
    width: 100%;
    height: 80vh;
    margin: auto;
    text-align: justify;
    /* text-align: center; */
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    text-align-last: center;
}

/* .psgDiv2 p{
    text-align: justify !important;
} */

.greyAboutBox {
    width: 50%;
    height: 300px;
    margin: auto;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
}

.greyAboutBox p {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.valueFirst {
    /* border: 2px solid black; */
    text-align: left;
}

.valueFirst h3 {
    color: #D00000;
    text-transform: uppercase;
    font-weight: 700;
}

.valueFirst p {
    width: 50%;
    text-align: left;
    /* border: 2px solid red; */
}

.valueSecond {
    /* border: 2px solid black; */
    text-align: right;
}

.valueSecond h3 {
    color: #D00000;
    text-transform: uppercase;
    font-weight: 700;
}

.valueSecond p {
    width: 60%;
    text-align: right;
    /* border: 2px solid red; */
    float: right;
    clear: both;
}

.strengthHeading {
    color: #D00000;
    font-size: 28px;
    font-weight: 700;
    margin: 20px 0px;
}

.aboutImgDiv {
    /* border: 2px solid black; */
    width: 200px;
    height: 170px;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutImgDiv img {
    width: 50%;
}

.aboutImgDiv p {
    font-size: 18px;
    color: #D00000;
    font-weight: 700;
    text-transform: uppercase;
}

.aboutDiv {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 40px;
    align-items: center;
    /* border: 2px solid black; */
    margin: 40px 0px;
}

.aboutImgDiv h3 {
    font-size: 20px;
    color: #D00000;
    font-weight: 700;
}

.IntegrityLine {
    color: black !important;
    font-size: 16px !important;
    padding: 0px 20px;
}

.briefHistoryImgdiv {
    /* border: 2px solid black; */
    width: 95%;
    height: auto;
    margin-bottom: 50px;
}

.briefHistoryImgdiv img {
    width: 100%;
    height: 100%;
}

#bHistory2 {
    display: none !important;
}

.showflip {
    display: none;
}

.aboutDiv2 {
    display: none;
}

.uploadResume2 {
    width: 102%;
    /* background-color: white; */
}

@media (max-width:590px) {
    .ReactModal__Content {
        /* border: 2px solid red !important; */
        width: 90%;
    }

    .ReactModal__Content--after-open {
        /* border: 2px solid red !important; */
        width: 90%;
    }

    .carreerResume {
        border: 1px solid #ced4da;
        padding: 6px 0px;
        background-color: #ededed;
        border-radius: 10px;
        text-align: center;
        margin: 20px 0px;
    }

    .uploadResume2 {
        width: 102%;
        /* background-color: white; */
        margin-top: 20px;
    }

    .hideFlip {
        display: none !important;
    }

    .showflip {
        display: block !important;
    }

    .psgDiv {
        /* border: 2px solid black; */
        padding: 40px 0px;
        width: 80%;
        margin: auto;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
    }

    .psgDivCareer {
        /* border: 2px solid black; */
        padding: 0px 0px;
        width: 80%;
        margin: auto;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
    }

    .aboutBackground {
        background: url('../../Assets/about/aboutus_mobile.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .psgDiv2 {
        /* border: 2px solid black; */
        padding: 20px 20px;
        width: 100%;
        height: auto;
        margin: auto;
        text-align: justify;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: white;
        text-align-last: center;
    }

    .FirstheadingDiv {
        margin-top: 60px;
    }



    .FirstheadingDiv h1 {
        font-size: 20px !important;
        font-weight: 700;
    }

    .greyAboutBox {
        width: 90%;
        height: 300px;
        margin: auto;
        background-color: #D9D9D9;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
    }

    .greyAboutBox p {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }

    .valueFirst p {
        width: 100%;
        text-align: left;
        font-size: 12px;
        /* border: 2px solid red; */
    }

    .valueSecond p {
        width: 100%;
        text-align: right;
        /* border: 2px solid red; */
        font-size: 12px;
        float: none;
        clear: both;
    }

    .strengthHeading {
        font-size: 14px;
    }

    .aboutDiv {
        margin: 0px;
        display: flex !important;
        justify-content: space-between;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        /* border: 2px solid black; */
    }

    .aboutImgDiv {
        /* border: 2px solid black; */
        width: 120px;
        height: 140px;
        margin: 5px;
    }

    .IntegrityLine {
        color: black !important;
        font-size: 12px !important;
        padding: 0px 10px;
    }

    .aboutImgDiv h3 {
        font-size: 18px;
        color: #D00000;
        font-weight: 700;
    }

    .aboutImgDiv img {
        width: 60%;
    }

    #bHistory {
        display: none !important;
    }

    #bHistory2 {
        display: block !important;
    }

    .briefHistoryImgdiv {
        /* border: 2px solid black; */
        width: 90%;
        height: auto;
        margin-bottom: 0px;
        margin: auto;
    }

    .aboutDiv2 {
        /* border: 2px solid black; */
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 30px;
    }

    .aboutDiv {
        display: none !important;
    }
}

@media (min-width:375px)and (max-width:389px) {
    .aboutDiv {
        margin: 0px;
        /* display: flex !important; */
        justify-content: space-between;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 30px;
        align-items: center;
        /* border: 2px solid black; */
    }
}

@media (min-width:390px)and (max-width:430px) {
    .aboutDiv {
        margin: 0px;
        /* display: flex !important; */
        justify-content: space-between;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 30px;
        align-items: center;
        /* border: 2px solid black; */
    }
}

@media (min-width:400px)and (max-width:430px) {
    .aboutDiv {
        margin: 0px;
        /* display: flex !important; */
        justify-content: space-between;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 35px;
        align-items: center;
        /* border: 2px solid black; */
    }
}

@media (min-width:431px)and (max-width:768px) {
    .psgDiv {
        padding: 50px 10px;
        width: 90%;
    }

    .psgDivCareer {
        padding: 20px 10px;
        width: 90%;
    }

    .currentOpening2 {
        width: 80%;
        margin: auto;
        padding: 12px;
        display: flex;
        justify-content: space-between;
    }

    .psgDiv2 {
        /* border: 2px solid black; */
        padding: 130px 20px !important;
        width: 100%;
        height: 32vh;
        margin: auto;
        text-align: center;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: white;
    }

    .greyAboutBox {
        width: 85%;
    }

    .greyAboutBox p {
        font-size: 18px;
    }

    .valueSecond p {
        width: 100%;
        text-align: right;
        /* border: 2px solid red; */
        font-size: 16px;
        float: none;
        clear: both;
    }

    .strengthHeading {
        font-size: 20px;
    }

    .aboutDiv {
        margin: 10px;
        display: flex !important;
        justify-content: space-between;
        justify-content: center;
        flex-wrap: wrap;
        gap: 25px !important;
        align-items: center;
        /* border: 2px solid black; */
    }
    .aboutDiv {
        display: none !important;  
    }

    .aboutDiv2 {
        display: grid !important;
        grid-template-columns: 1fr !important;
        row-gap: 30px;
        place-items: center;
    }
    .hideFlip {
        display: none !important;
    }

    .showflip {
        display: block !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }


    .aboutImgDiv {
        width: 140px;
        height: 150px;
    }

    .aboutImgDiv img {
        /* border: 2px solid red; */
        width: 60%;
    }

    .IntegrityLine {
        color: black !important;
        font-size: 12px !important;
        padding: 0px 10px;
    }

    .aboutImgDiv h3 {
        font-size: 16px;
        color: #D00000;
        font-weight: 700;
    }

    .briefHistoryImgdiv {
        /* border: 2px solid black; */
        width: 100%;
        height: auto;
        margin-bottom: 50px;
    }

    .briefHistoryImgdiv img {
        width: 100%;
        height: 100%;
    }
    .submitOpeing {
        border: none;
        border-radius: 21px;
        padding: 8px 28px;
        background-color: #D00000;
        color: white;
        text-transform: uppercase;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        margin-top: 20px;
    }

}

@media(min-width:431px) and (max-width:650px) {
    .aboutImgDiv {
        width: 100px;
        height: 100px;
    }

    .aboutImgDiv img {
        /* border: 2px solid red; */
        width: 60%;
    }

    .IntegrityLine {
        color: black !important;
        font-size: 8px !important;
        padding: 0px 10px;
    }

    .aboutImgDiv h3 {
        font-size: 12px;
        color: #D00000;
        font-weight: 700;
    }

    .aboutImgDiv p {
        font-size: 13px;
    }

    .psgDiv2 {
        /* border: 2px solid black; */
        padding: 80px 20px !important;
        width: 100%;
        height: 40vh;
        margin: auto;
        text-align: center;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: white;
    }
}

@media(min-width:769px) and (max-width:1250px) {

    .aboutImgDiv {
        width: 250px !important;
        height: 250px !important;
        /* border: 2px solid black; */
    }

    .aboutImgDiv img {
        /* border: 2px solid red; */
        width: 60%;
    }

    .IntegrityLine {
        color: black !important;
        font-size: 13px !important;
        padding: 0px 10px;
    }

    .aboutImgDiv h3 {
        font-size: 16px;
        color: #D00000;
        font-weight: 700;
    }

    .aboutImgDiv p {
        font-size: 16px;
    }

    .psgDiv {
        padding: 50px 10px;
        width: 90%;
    }

    .psgDivCareer {
        padding: 20px 10px;
        width: 90%;
    }

    .psgDiv2 {
        /* border: 2px solid black; */
        padding: 150px 20px !important;
        width: 100%;
        height: 32vh;
        margin: auto;
        text-align: center;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: white;
    }

    .strengthHeading {
        font-size: 22px;
    }

    .valueSecond p {
        width: 100%;
        text-align: right;
        /* border: 2px solid red; */
        font-size: 16px;
        float: none;
        clear: both;
    }

    .valueFirst p {
        width: 60%;
        text-align: left;
        /* border: 2px solid red; */
    }

    .greyAboutBox {
        width: 85%;
    }

    .greyAboutBox p {
        font-size: 20px;
    }

    .briefHistoryImgdiv {
        /* border: 2px solid black; */
        width: 100%;
        height: auto;
        margin-bottom: 50px;
    }

    .briefHistoryImgdiv img {
        width: 100%;
        height: 100%;
    }
    .submitOpeing {
        border: none;
        border-radius: 21px;
        padding: 8px 28px;
        background-color: #D00000;
        color: white;
        text-transform: uppercase;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        margin-top: 20px;
    }
}

@media (min-width:1800px) {
    .FirstheadingDiv {
        background-color: #D00000;
        text-align: center;
        padding: 10px 0px;
        color: white;
        text-transform: uppercase;
        margin-top: 90px;
    }
}


.ReactModal__Overlay {
    position: fixed;
    inset: 0px;
    background-color: rgb(120 119 119 / 75%) !important;
}

.ReactModal__Overlay--after-open {
    position: fixed;
    inset: 0px;
    background-color: rgb(120 119 119 / 75%) !important;
}

.newModalhead {
    text-align: center;
    font-weight: 700;
    color: black !important;
    font-size: 30px;
}

.carreerResume2 {
    border: 1px solid #ced4da;
    padding: 8px 0px;
    background-color: white;
    border-radius: 6px;
    text-align: center;
}

.carreerResume {
    border: 1px solid #ced4da;
    padding: 6px 0px;
    background-color: #ededed;
    border-radius: 10px;
    text-align: center;
}

.label {
    cursor: pointer;
    color: gray !important;
    background-color: transparent !important;
    font-family: inherit !important;
}

#upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}


@media (min-width:431px)and (max-width:500px) {
    .aboutImgDiv {
        /* border: 2px solid black; */
        width: 360px !important;
        height: 240px !important;
        margin: 5px;
    }
}


@media (min-width: 601px) and (max-width: 1000px) {
    .aboutDiv {
        display: none !important;  
    }
}