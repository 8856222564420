.mainWhyDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red;  */
    width: 90%;
    margin: auto;
}

.whyLeftDiv {
    /* border: 2px solid red; */
    width: 800px;
    /* height: 800px; */
    height: 705px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 40px;
}



.wLeftImgDiv {
    width: 450px;
    height: 450px;
    /* border: 2px solid black; */
}

.wLeftImgDiv img {
    width: 100%;
    height: 100%;
}

.wRightMain {
    /* border: 2px solid black; */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 20px;
    margin: 20px 0px;
}

.wTextDiv {
    width: 80%;
    color: #DA1F24;
}

.wRightImgDiv {
    width: 24%;
    height: 24%;
    border: 2px solid green;
    /* object-fit: contain; */
}

.wRightImgDiv img {
    width: 100%;
    height: 100%;
}

.wBlueDiv {
    position: absolute;
    background: #DA1F24;
    /* text-transform: uppercase; */
    color: white;
    /* width: 45%; */
    width: 95%;
    padding: 20px;
    top: 66px;
    right: 0px;
    text-align: center;
    /* border: 2px solid black; */
}

.wBlueDiv h1 {
    font-size: 30px !important;
    font-weight: 700 !important;
    letter-spacing: 2px;
    font-family: 'Hedvig Letters Sans', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'PT Sans', sans-serif;
    /* border: 1px solid; */

}

.whymainPic {
    /* border: 2px solid black; */
    width: 650px;
    height: 650px;
}

.whymainPic img {
    width: 100%;
    height: 100%;
    /* transition: transform 3s ease-in-out; */
}

/* .whymainPic img:hover{
    transform: rotate(360deg);
} */

@media (max-width:600px) {
    .mainWhyDiv {
        /* border: 2px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: auto;
        flex-direction: column;
        margin-top: 45px;
    }

    .whyLeftDiv {
        /* border: 2px solid red; */
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 0px;
    }

    .wLeftImgDiv {
        width: 300px;
        height: 300px;
        /* border: 2px solid red; */
    }

    .wLeftImgDiv img {
        width: 100%;
        height: 100%;
    }

    .wBlueDiv {
        position: absolute;
        background: #DA1F24;
        /* text-transform: uppercase; */
        color: white;
        /* width: 55%; */
        width: 111%;
        padding: 10px;
        /* bottom: -10px; */
        top: -25px;
        right: -16px;

    }

    .wBlueDiv h1 {
        font-size: 15px !important;
        letter-spacing: 2px;
        font-family: 'Hedvig Letters Sans', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-family: 'PT Sans', sans-serif;
        /* border: 1px solid; */
        /* margin-bottom: 0px; */
    }

    .whyRightDiv {
        /* border: 2px solid green; */
        width: 95%;
        margin-top: 23px;
    }

    .wRightMain {
        /* border: 2px solid black; */
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        gap: 20px;
        margin: 20px 0px;
    }

    .wRightImgDiv {
        width: 55%;
        height: 55%;
        /* border: 1px solid black; */
        /* width: 180px;
        height: 145px; */
    }

    .wRightImgDiv img {
        width: 100%;
        height: 100%;
    }

    .wTextDiv h3 {
        font-size: 18px;
    }

    .wTextDiv p {
        font-size: 12px;
    }

    .whymainPic {
        /* border: 2px solid black; */
        width: 100%;
        height: auto;
    }
}





@media (min-width:601px) and (max-width:1000px) {
    .mainWhyDiv {
        /* border: 2px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: auto;
        /* flex-direction: column; */
        margin-top: 45px;
    }

    .whyLeftDiv {
        /* border: 2px solid red; */
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 0px;
    }

    .wLeftImgDiv {
        width: 300px;
        height: 300px;
        /* border: 2px solid red; */
    }

    .wLeftImgDiv img {
        width: 100%;
        height: 100%;
    }

    .wBlueDiv {
        position: absolute;
        background: #DA1F24;
        /* text-transform: uppercase; */
        color: white;
        /* width: 55%; */
        width: 111%;
        padding: 10px;
        /* bottom: -10px; */
        top: -25px;
        right: -16px;

    }

    .wBlueDiv h1 {
        font-size: 15px !important;
        letter-spacing: 2px;
        font-family: 'Hedvig Letters Sans', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-family: 'PT Sans', sans-serif;
        /* border: 1px solid; */
        /* margin-bottom: 0px; */
    }

    .whyRightDiv {
        /* border: 2px solid green; */
        width: 95%;
        margin-top: 23px;
    }

    .wRightMain {
        /* border: 2px solid black; */
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        gap: 20px;
        margin: 20px 0px;
    }

    .wRightImgDiv {
        width: 55%;
        height: 55%;
        /* border: 1px solid black; */
        /* width: 180px;
        height: 145px; */
    }

    .wRightImgDiv img {
        width: 100%;
        height: 100%;
    }

    .wTextDiv h3 {
        font-size: 18px;
    }

    .wTextDiv p {
        font-size: 12px;
    }

    .whymainPic {
        /* border: 2px solid black; */
        width: 100%;
        height: auto;
    }
}

@media (min-width:1001px) and (max-width:1300px) {
    .mainWhyDiv {
        /* border: 2px solid black; */
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 90%;
        margin: auto;
        /* flex-direction: column; */
        margin-top: 45px;
    }

    .whyLeftDiv {
        /* border: 2px solid red; */
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 0px;
    }

    .wLeftImgDiv {
        width: 300px;
        height: 300px;
        /* border: 2px solid red; */
    }

    .wLeftImgDiv img {
        width: 100%;
        height: 100%;
    }

    .wBlueDiv {
        position: absolute;
        background: #DA1F24;
        /* text-transform: uppercase; */
        color: white;
        /* width: 55%; */
        width: 111%;
        padding: 10px;
        /* bottom: -10px; */
        top: -25px;
        right: -16px;

    }

    .wBlueDiv h1 {
        font-size: 15px !important;
        letter-spacing: 2px;
        font-family: 'Hedvig Letters Sans', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-family: 'PT Sans', sans-serif;
        /* border: 1px solid; */
        /* margin-bottom: 0px; */
    }

    .whyRightDiv {
        /* border: 2px solid green; */
        width: 45%;
        margin-top: 23px;
    }

    .wRightMain {
        /* border: 2px solid black; */
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        gap: 20px;
        margin: 20px 0px;
    }

    .wRightImgDiv {
        width: 55%;
        height: 55%;
        /* border: 1px solid black; */
        /* width: 180px;
        height: 145px; */
    }

    .wRightImgDiv img {
        width: 100%;
        height: 100%;
    }

    .wTextDiv h3 {
        font-size: 18px;
    }

    .wTextDiv p {
        font-size: 12px;
    }

    .whymainPic {
        /* border: 2px solid black; */
        width: 100%;
        height: auto;
    }
}


@media (min-width:1800px) {
    .wBlueDiv h1 {
        font-size: 40px !important;
        font-weight: 700 !important;
        letter-spacing: 2px;
        font-family: 'Hedvig Letters Sans', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-family: 'PT Sans', sans-serif;
    }
}