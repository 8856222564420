.MainFaq{
    /* border: 2px solid black; */
    margin: 50px 0px;
    padding: 0px 130px;
}
.headingFaq{
    text-align: center;
    color: black;
    font-size: 40px;
    font-weight: 700;
}

@media (max-width:500px){
    .MainFaq{
        /* border: 2px solid black; */
        margin: 80px 0px;
        padding: 0px 20px;
    }
    .headingFaq{
        text-align: center;
        color: black;
        font-size: 20px;
        font-weight: 700;
    }
}

@media (min-width:501px) and (max-width:1400px){
    .MainFaq{
        /* border: 2px solid black; */
        margin: 80px 0px;
        padding: 0px 50px;
    }
    .headingFaq{
        text-align: center;
        color: black;
        font-size: 20px;
        font-weight: 700;
    }
}