.icsetenTopDiv {
    /* border: 2px solid yellow; */
    padding: 0px 150px;
}

.icsetenMain {
    /* border: 2px solid black; */
    margin-top: 150px;
    display: flex;
    align-items: center;
}

.icsetenLeftDiv {
    /* border: 2px solid red; */
    width: 1000px !important;
    height: 450px !important;
}

.icsetenLeftDiv img {
    width: 100%;
    height: 100%;
}

.icsetenRightDiv {
    /* border: 2px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.icsetenRightDiv h3 {
    color: #D00000;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
}

.icsetenRightDiv p {
    color: #000;
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
    line-height: 34.5px;
}

.icsetenSecondDiv {
    /* border: 2px solid black; */
    background-color: #D00000;
    padding: 50px 150px;
    margin-top: 50px;
}

.icsetenSeconDiv h1 {
    color: white;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.icsetenSeconMainDiv {
    /* border: 2px solid green; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.icsetenMultipleText {
    /* border: 2px solid black; */
    width: 500px;
    height: 160px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.icsetenMultipleInnerDiv {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
}

.icsetenMultipleInnerDiv img {
    width: 4%;
    height: 100%;
    margin-right: 10px;
}

.icsetenMultipleInnerDiv h3 {
    color: white;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.36px;
    margin-top: 8px;
}

.icsetenMultipleText p {
    color: white;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
}

.icsetenThirdDiv{
    /* border: 2px solid black; */
    padding: 50px 150px;
}

.icsetenMainDiv2{
    /* border: 2px solid black; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.icsetenMultipleText2 {
    /* border: 2px solid black; */
    width: 500px;
    height: 180px;
    margin-right: 50px;
    margin-bottom: 20px;
}

#matrixBoxx{
    /* border: 2px solid green; */
    width: 500px;
    height: 260px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.icsetenMultipleInnerDiv2 {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
}

.icsetenMultipleInnerDiv2 img {
    width: 4%;
    height: 100%;
    margin-right: 10px;
}

.icsetenMultipleInnerDiv2 h3 {
    color: #D00000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.36px;
    margin-top: 8px;
}

.icsetenMultipleText2 p {
    color: black;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
    text-align: justify;
}
.icsetenThirdDiv h1 {
    color: black;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.matrixDiv{
    /* border: 2px solid green; */
    width: 200px;
    height: 200px;
}
.matrixDiv img{
    width: 100%;
    height: 100%;
}

.icsetenLastDiv {
    padding: 20px 150px;
}

.icsetenFaq {
    color: #000;
    font-size: 35.898px;
    font-weight: 400;
}
.icsetenTriviaImgDiv{
    /* border: 2px solid black; */
    margin-bottom: 50px;
    width: 100%;
    height: auto;
}
.icsetenTriviaImgDiv img{
    width: 100%;
    height: 100%;
}






@media (max-width:500px) {
    .icsetenTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 20px;
    }

    .icsetenMain {
        /* border: 2px solid black; */
        margin-top: 80px;
        display: flex;
        flex-direction: column;
    }

    .icsetenLeftDiv {
        width: 100% !important;
        height: 100% !important;
    }

    .icsetenRightDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    .icsetenRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 12px;
        font-weight: 400;
        line-height: 24.5px;
    }

    .icsetenRightDiv {
        /* border: 2px solid green; */
        margin-top: 20px;
        padding-left: 0px;
    }

    .icsetenSecondDiv {
        /* border: 2px solid black; */
        background-color: #D00000;
        padding: 50px 21px;
        margin-top: 50px;
    }

    .icsetenSeconDiv h1 {
        color: white;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .icsetenSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .icsetenMultipleText {
        /* border: 2px solid black; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .icsetenMultipleInnerDiv {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .icsetenMultipleInnerDiv h3 {
        color: white;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .icsetenMultipleText p {
        color: white;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }
    .icsetenLastDiv {
        padding: 20px 10px;
    }

    .icsetenFaq {
        color: #000;
        font-size: 22.898px;
        font-weight: 400;
        margin-left: 10px;
    }
    .icsetenThirdDiv {
        /* border: 2px solid black; */
        padding: 50px 20px;
    }
    .icsetenThirdDiv h1 {
        color: black;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }
    .icsetenMultipleText2 {
        /* border: 2px solid black; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 0px;
    }
    .icsetenMultipleInnerDiv2 {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    /* #Simplify{
        width: 500px;
        height: 170px;
        margin-right: 0px;
        margin-bottom: 0px;
    } */
    .icsetenMultipleInnerDiv2 h3 {
        color: #D00000;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }
    .icsetenMultipleText2 p {
        color: black;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }
    #matrixBoxx {
        /* border: 2px solid green; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .icsetenTriviaImgDiv{
        /* border: 2px solid black; */
        margin-bottom: 0px;
        width: 100%;
        height: auto;
    }
    .icsetenTriviaImgDiv img{
        width: 100%;
        height: 100%;
    }
    #icseFirst{
        /* border: 2px solid black ; */
        width: 500px;
        height: auto !important;
        margin-right: 0px;
        margin-bottom: 0px;
    }
    /* #learnunder{
        font-size: 13px;
    } */
}

@media (min-width:1800px){
    .icsetenSeconDiv {
        margin-top: 50px;
        /* border: 2px solid black; */
    }
    .icsetenSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        justify-content: flex-start;
        align-items:start;
    }
    .icsetenMultipleText {
        /* border: 2px solid red; */
        width: 460px;
        height: 160px;
        margin-right: 85px;
        /* margin-left: 50px; */
        margin-bottom: 20px;
    }
    .icsetenMainDiv2{
        /* border: 2px solid black; */
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
    
    .icsetenMultipleText2 {
        /* border: 2px solid black; */
        width: 450px;
        height: 220px;
        margin-right: 90px;
        margin-bottom: 20px;
    }
    .icsetenTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 200px;
    }
    .icsetenSecondDiv {
        /* border: 2px solid black; */
        background-color: #D00000;
        padding: 50px 60px 50px 200px;
        margin-top: 50px;
    }
    .icsetenThirdDiv {
        /* border: 2px solid black; */
        /* padding: 50px 200px; */
        padding: 50px 60px 50px 200px;
    }
    .matrixDiv2{
        width: 70%;
    }
    .matrixDiv2 img{
        width: 100%;
    }
    .icsetenMultipleInnerDiv2 h3 {
        color: #D00000;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }
    .icsetenMultipleInnerDiv h3 {
        color: white;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }
    .icsetenLastDiv {
        padding: 20px 200px;
    }
    .icsetenMultipleText2 p {
        color: black;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: justify;
    }
}


@media (min-width:1000px)and (max-width:1396px) {
    .icsetenMultipleText {
        /* border: 2px solid black; */
        width: 500px;
        height: 160px;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .icsetenMultipleText2 {
        /* border: 2px solid black; */
        width: 500px;
        height: 180px;
        margin-right: 0px;
        margin-bottom: 20px;
    }
}