.elevenTopDiv {
    /* border: 2px solid yellow; */
    padding: 0px 150px;
}

.elevenMain {
    /* border: 2px solid black; */
    margin-top: 50px;
    display: flex;
    align-items: center;
}

.elevenLeftDiv {
    /* border: 2px solid red; */
    width: 1000px !important;
    height: 450px !important;
}

.elevenLeftDiv img {
    width: 100%;
    height: 100%;
}

.elevenRightDiv {
    /* border: 2px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.elevenRightDiv h3 {
    color: #D00000;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
}

.elevenRightDiv p {
    color: #000;
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
    line-height: 34.5px;
}

.ElevenSeconDiv {
    margin-top: 50px;
}

.ElevenSeconDiv h1 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.ElevenSeconMainDiv {
    /* border: 2px solid green; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.elevenMultipleText {
    /* border: 2px solid black; */
    width: 500px;
    height: 160px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.elevenMultipleInnerDiv {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
}

.elevenMultipleInnerDiv img {
    width: 4%;
    height: 100%;
    margin-right: 10px;
}

.elevenMultipleInnerDiv h3 {
    color: #D00000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.36px;
    margin-top: 8px;
}

.elevenMultipleText p {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
}

.elevenThirdDiv h1 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 30px;
}

.elevenTipsDiv {
    /* border: 2px solid red; */
    margin: 30px 0px;
    display: flex;
    width: 530px;
    height: 190px;
}

.eleventipsImgDiv {
    /* border: 2px solid green; */
    /* width: 350px; */
    height: 120px;
    margin-right: 20px;
}

.elevenTipsDiv img {
    /* width: 100%; */
    height: 100%;
    max-width: none;
}

.eleventipsInner {
    /* border: 2px solid black;  */
}

.eleventipsInner h3 {
    color: #D00000;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.36px;
}

.eleventipsInner p {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
    text-align: justify;
}

.elevenMainTips {
    /* border: 2px solid yellow; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.elevenfourthTips {
    background-color: #D00000;
    padding-top: 40px;
}

.elevenfourthInner {
    /* border: 2px solid black; */
    padding: 0px 150px;
}

.elevenfourthInner h3 {
    color: #FFF;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 1.08px;
    text-transform: uppercase;
    /* margin-top: 50px; */
}

.elevenFourthRed {
    /* border: 2px solid green; */
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.redBoxxImgdiv {
    /* border: 2px solid yellow; */
    width: 200px;
    height: 200px;
}

.redBoxxImgdiv img {
    width: 100%;
    height: 100%;
}

.redBoxxImghead {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
}

.elevenFourthMain {
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.elevenLastDiv {
    padding: 20px 150px;
}

.elevenFaq {
    color: #000;
    font-size: 35.898px;
    font-weight: 400;
}

@media (max-width:600px) {
    .elevenTopDiv {
        /* border: 2px solid yellow; */
        padding: 0px 20px;
    }

    .elevenMain {
        /* border: 2px solid black; */
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .elevenLeftDiv {
        width: 100% !important;
        height: 100% !important;
    }

    .elevenRightDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    .elevenRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 12px;
        font-weight: 400;
        line-height: 24.5px;
    }

    .elevenRightDiv {
        /* border: 2px solid green; */
        margin-top: 20px;
        padding-left: 0px;
    }

    .ElevenSeconDiv {
        margin-top: 50px;
        /* border: 2px solid green; */
    }

    .ElevenSeconDiv h1 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .ElevenSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .elevenMultipleText {
        /* border: 2px solid black; */
        width: 500px;
        height: auto;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .elevenMultipleInnerDiv {
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .elevenMultipleInnerDiv h3 {
        color: #D00000;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-top: 8px;
    }

    .elevenMultipleText p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
        text-align: center;
    }

    .elevenThirdDiv h1 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;
        margin-bottom: 30px;
        text-align: center;
    }

    .elevenTipsDiv {
        /* border: 2px solid red; */
        margin: 30px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 530px;
        height: auto;
    }

    .eleventipsImgDiv {
        /* border: 2px solid green; */
        /* width: 350px; */
        height: 80px;
        margin-right: 20px;
    }

    .eleventipsInner h3 {
        color: #D00000;
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
        font-weight: 700;
        letter-spacing: -0.36px;
    }

    .eleventipsInner p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        letter-spacing: -0.24px;
    }

    .elevenLastDiv {
        padding: 20px 10px;
    }

    .elevenFaq {
        color: #000;
        font-size: 22.898px;
        font-weight: 400;
        margin-left: 10px;
    }

    .accordion__button {
        background-color: #f4f4f4;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
        font-size: 10px;
    }

    .accordion__panel {
        font-size: 10px;
        padding: 20px px;
        animation: fadein 0.35s ease-in;
    }

    .elevenfourthTips {
        background-color: #D00000;
        padding-top: 40px;
    }

    .elevenfourthInner {
        /* border: 2px solid black; */
        padding: 0px 10px;
    }

    .elevenFourthMain {
        /* border: 2px solid black; */
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        flex-direction: column;
    }

    .elevenfourthInner h3 {
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        text-align: center;
        /* margin-top: 50px; */
    }

    .redBoxxImgdiv {
        /* border: 2px solid yellow; */
        width: 150px;
        height: 150px;
    }

    .redBoxxImghead {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
    }

    .elevenFourthRed {
        /* border: 2px solid green; */
        width: 270px;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media (min-width:1800px) {
    .ElevenSeconDiv {
        margin-top: 50px;
        /* border: 2px solid black; */
    }

    .ElevenSeconMainDiv {
        /* border: 2px solid green; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .elevenMultipleText {
        /* border: 2px solid red; */
        width: 450px;
        height: 160px;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .eleventipsInner {
        /* border: 2px solid black; */
        width: 65%;
    }

    .elevenTipsDiv {
        /* border: 2px solid red; */
        margin: 30px 0px;
        display: flex;
        width: 510px;
        height: 190px;
        justify-content: center;
    }

    .elevenTopDiv {
        border: 2px solid yellow;
        padding: 0px 200px;
    }

    .elevenLastDiv {
        padding: 20px 200px;
    }

    .elevenfourthInner {
        /* border: 2px solid black; */
        padding: 0px 200px;
    }
}


@media (min-width: 601px) and (max-width: 1000px) {
    .elevenTopDiv {
        /* border: 2px solid black; */
        padding: 0px 50px;
    }

    .elevenMain {
        /* border: 2px solid black; */
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .elevenLeftDiv {
        width: auto !important;
        height: 450px !important;
    }

    .elevenRightDiv h3 {
        color: #D00000;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin: 30px 0px;
        text-transform: uppercase;
    }

    .elevenMultipleText {
        /* border: 2px solid black; */
        width: 620px;
        height: 160px;
        margin-right: 50px;
        margin-bottom: 20px;
    }

    .elevenTipsDiv {
        /* border: 2px solid red; */
        margin: 20px 0px;
        display: flex;
        width: 100%;
        height: 190px;
    }

    .elevenfourthInner {
        /* border: 2px solid black; */
        padding: 0px 50px;
    }

    .redBoxxImgdiv {
        /* border: 2px solid yellow; */
        width: 160px !important;
        height: 160px !important;
    }

    .redBoxxImghead {
        color: #FFF;
        text-align: center;
        font-size: 14px !important;
        font-weight: 700;
        margin-top: 20px;
    }

    .elevenFourthRed {
        /* border: 2px solid green; */
        width: 400px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .elevenLastDiv {
        padding: 20px 50px;
    }
}


@media (min-width:1001px)and (max-width:1396px) {
    .elevenTopDiv {
        /* border: 2px solid black; */
        padding: 0px 50px;
    }
    .elevenRightDiv p {
        color: #000;
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: 25.5px;
    }
    .elevenRightDiv h3 {
        color: #D00000;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
    }
    .elevenMultipleText {
        /* border: 2px solid black; */
        width: 420px;
        height: 160px;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .elevenTipsDiv {
        /* border: 2px solid red; */
        margin: 30px 0px;
        display: flex;
        /* width: 502px; */
        width: 100%;
        height: 190px;
    }
    .elevenfourthInner {
        /* border: 2px solid black; */
        padding: 0px 50px;
    }

    .redBoxxImgdiv {
        /* border: 2px solid yellow; */
        width: 160px !important;
        height: 160px !important;
    }

    .redBoxxImghead {
        color: #FFF;
        text-align: center;
        font-size: 19px !important;
        font-weight: 700;
        margin-top: 20px;
    }
    .elevenLastDiv {
        padding: 20px 50px;
    }
}