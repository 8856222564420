.mainVideoDiv {
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainVideoDivcbse {
    /* border: 2px solid black; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 60px;
}

.youtubeDiv {
    /* border: 2px solid black; */
    margin: 50px auto 70px auto;
    width: 30%;
    height: 250px;
}
.youtubeDivcbse {
    /* border: 2px solid black; */
    margin: 20px auto 20px auto;
    width: 90%;
    height: 250px;
}

.youtubeDivcbse img {
    width: 100%;
    height: 100%;
}
.youtubeDiv img {
    width: 100%;
    height: 100%;
}

.rectangleImg {
    /* border: 1px solid green; */
    width: 100%;
    height: 100%;
}
.videolatestheading{
    text-align: center;
    color: black;
}


@media (max-width:430px) {
    .mainVideoDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* border: 2px solid black; */
        margin: 30px 0px;
    }

    .youtubeDiv {
        /* border: 2px solid black; */
        margin: 10px auto;
        width: 85%;
        height: 180px;
    }

    .youtubeDiv img {
        width: 100%;
        height: 100%;
    }

    .rectangleImg {
        /* border: 1px solid green; */
        width: 100%;
        height: 95%;
    }
    .videolatestheading{
        font-size: 13px;
    }
    .mainVideoDivcbse {
        /* border: 2px solid black; */
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 60px;
    }
    .youtubeDivcbse {
        /* border: 2px solid black; */
        margin: 20px auto 20px auto;
        width: 90%;
        height: auto;
    }
}

@media (min-width:431px) and (max-width:600px) {
    .mainVideoDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* border: 2px solid black; */
        margin: 30px 0px;
    }
    .youtubeDiv {
        /* border: 2px solid black; */
        margin: 10px auto;
        width: 85%;
        height: 230px;
    }

    .youtubeDiv img {
        width: 100%;
        height: 100%;
    }

    .rectangleImg {
        /* border: 1px solid green; */
        width: 100%;
        height: 95%;
    }
    .mainVideoDivcbse {
        /* border: 2px solid black; */
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 60px;
    }
    .youtubeDivcbse {
        /* border: 2px solid black; */
        margin: 20px auto 20px auto;
        width: 90%;
        height: auto;
    }
}

@media (min-width:601px) and (max-width:930px) {
    .mainVideoDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* border: 2px solid black; */
        margin: 30px 0px;
    }
    .youtubeDiv {
        /* border: 2px solid black; */
        margin: 10px auto;
        width: 85%;
        height: 280px;
    }

    .youtubeDiv img {
        width: 100%;
        height: 100%;
    }

    .rectangleImg {
        /* border: 1px solid green; */
        width: 100%;
        height: 95%;
    }
    .mainVideoDivcbse {
        /* border: 2px solid black; */
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 60px;
    }
    .youtubeDivcbse {
        /* border: 2px solid black; */
        margin: 20px auto 20px auto;
        width: 90%;
        height: 250px;
    }
}