.higherTopper {
    /* border: 2px solid yellow; */
    padding: 0px 100px;
    margin-top: 70px;
}

.mainTopperDiv {
    /* border: 2px solid black; */
    position: relative;
    display: none;
}

.BigHeading {
    text-align: right;
}

.BigHeading h1 {
    font-size: 120px;
    color: #EDEDED;
    font-weight: 700;
    /* border: 2px solid red; */
}

.smallHeading {
    /* border: 2px solid green; */
    position: absolute;
    top: 50px;
    left: 20px;
}

.smallHeading h1 {
    font-size: 65px;
    color: #D00000;
    font-weight: 700;
}

#toppers {
    color: #1E1E1E;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    /* border: 2px solid black; */
    margin-bottom: -30px;
}

.oTopDiv{
    width: 80%;
    /* border: 2px solid black; */
    /* height: 100% !important; */
    margin: auto;
}


.oTopDiv img{
    width: 100%;
}
.toppersCarousel{
    display: none;
}


/* slider  */
.featureCaurnew{
    /* border: 2px solid black; */
    width: 80% !important;
    margin: auto;
}
.slick-dots {
    position: absolute;
    bottom: -10px;
    display: block;
    width: 100%;
    padding: 0px;
    list-style: none;
    text-align: center;
}


@media (max-width:430px){
    .featureCaurnew{
        /* border: 2px solid black; */
        width: 85% !important;
        margin: auto;
    }
    .higherTopper{
        /* display: none; */
        /* border: 2px solid black; */
        padding: 0px 10px;
        margin-top: 70px;
        margin-bottom: 20px;
    }
    #toppers{
        font-size: 20px !important;
        margin-bottom: 0px;
    }
    .oTopDiv{
        width: 100%;
        /* border: 2px solid black; */
        margin: auto;
        display: none;
    }
    
    .oTopDiv img{
        width: 100%;
    }
    .toppersCarousel{
        display: block !important;
    }
 }
 @media (min-width:430px) and (max-width:600px) {
    .higherTopper{
        /* display: none; */
        /* border: 2px solid black; */
        padding: 0px 10px;
        margin-top: 70px;
        margin-bottom: 20px;
    }
    #toppers{
        font-size: 20px !important;
        margin-bottom: 0px;
    }
    .BigHeading h1 {
        font-size: 50px;
        color: #EDEDED;
        font-weight: 700;
        /* border: 2px solid red; */
    }
    .smallHeading {
        /* border: 2px solid green; */
        position: absolute;
        top: 20px;
        left: 20px;
    }
    .smallHeading h1 {
        font-size: 28px;
        color: #D00000;
        font-weight: 700;
    }
    .oTopDiv{
        width: 100%;
        /* border: 2px solid black; */
        margin: auto;
    }
    
    .oTopDiv img{
        width: 100%;
    }
 }
 @media (min-width:601px) and (max-width:768px) {
    .higherTopper{
        /* display: none; */
        /* border: 2px solid black; */
        padding: 0px 10px;
        margin-top: 70px;
        margin-bottom: 20px;
    }
    #toppers{
        font-size: 20px !important;
        margin-bottom: 0px;
    }
    .BigHeading h1 {
        font-size: 60px;
        color: #EDEDED;
        font-weight: 700;
        /* border: 2px solid red; */
    }
    .smallHeading {
        /* border: 2px solid green; */
        position: absolute;
        top: 20px;
        left: 20px;
    }
    .smallHeading h1 {
        font-size: 45px;
        color: #D00000;
        font-weight: 700;
    }
    #toppers {
        color: #1E1E1E;
        text-align: center;
        font-size: 35px !important;
        font-weight: 700;
        /* border: 2px solid black; */
        margin-bottom: -10px;
    }
    .oTopDiv{
        width: 100%;
        /* border: 2px solid black; */
        margin: auto;
    }
    
    .oTopDiv img{
        width: 100%;
    }
 }

 