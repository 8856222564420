.aboutBackground {
    /* background: url('../../Assets/about/aboutus_web.png'); */
    background: url('../../Assets/about/anout\ us-web.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.headingDiv {
    background-color: #D00000;
    text-align: center;
    padding: 10px 0px;
    color: white;
    text-transform: uppercase;
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.FirstheadingDiv {
    background-color: #D00000;
    text-align: center;
    padding: 10px 0px;
    color: white;
    text-transform: uppercase;
    margin-top: 0px !important;
}

.FirstheadingDiv h1 {
    font-size: 30px !important;
    margin-top: 8px;
}

.headingDiv h1 {
    font-size: 30px !important;
    margin-top: 8px;
    /* border: 2px solid black; */
}

.psgDiv {
    /* border: 2px solid black; */
    padding: 80px;
    width: 80%;
    margin: auto;
    text-align: center;
    font-weight: 600;
}

.psgDiv2 {
    /* border: 2px solid black; */
    padding: 0px 120px;
    width: 100%;
    height: 80vh;
    margin: auto;
    text-align: justify;
    /* text-align: center; */
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    text-align-last: center;
}

/* .psgDiv2 p{
    text-align: justify !important;
} */

.greyAboutBox {
    width: 50%;
    height: 300px;
    margin: auto;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
}

.greyAboutBox p {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.valueFirst {
    /* border: 2px solid black; */
    text-align: left;
}

.valueFirst h3 {
    color: #D00000;
    text-transform: uppercase;
    font-weight: 700;
}

.valueFirst p {
    width: 50%;
    text-align: left;
    /* border: 2px solid red; */
}

.valueSecond {
    /* border: 2px solid black; */
    text-align: right;
}

.valueSecond h3 {
    color: #D00000;
    text-transform: uppercase;
    font-weight: 700;
}

.valueSecond p {
    width: 60%;
    text-align: right;
    /* border: 2px solid red; */
    float: right;
    clear: both;
}

.strengthHeading {
    color: #D00000;
    font-size: 28px;
    font-weight: 700;
    margin: 20px 0px;
}

.aboutImgDiv {
    /* border: 2px solid black; */
    width: 300px !important;
    height: 300px !important;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutImgDiv img {
    width: 45% !important;
}

.aboutImgDiv p {
    font-size: 18px;
    color: #D00000;
    font-weight: 700;
    text-transform: uppercase;
}

.flipfrontPara {
    margin-top: 20px;
}

.aboutDiv {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 40px;
    align-items: center;
    /* border: 2px solid black; */
    margin: 40px 0px;
}

.aboutImgDiv h3 {
    font-size: 20px;
    color: #D00000;
    font-weight: 700;
}

.IntegrityLine {
    color: black !important;
    font-size: 16px !important;
    padding: 0px 20px;
}

.briefHistoryImgdiv {
    /* border: 2px solid black; */
    width: 95%;
    height: auto;
    margin-bottom: 50px;
}

.briefHistoryImgdiv img {
    width: 100%;
    height: 100%;
}

#bHistory2 {
    display: none !important;
}

.showflip {
    display: none;
}

.aboutDiv2 {
    display: none;
}

@media (max-width:530px) {
    .hideFlip {
        display: none !important;
    }

    .showflip {
        display: block !important;
    }

    .psgDiv {
        /* border: 2px solid black; */
        padding: 40px 0px;
        width: 80%;
        margin: auto;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
    }

    .aboutBackground {
        background: url('../../Assets/about/aboutus_mobile.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .psgDiv2 {
        /* border: 2px solid black; */
        padding: 20px 20px;
        width: 100%;
        height: auto;
        margin: auto;
        text-align: justify;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: white;
        text-align-last: center;
    }

    .FirstheadingDiv {
        margin-top: 60px !important;
    }

    .headingDiv h1 {
        font-size: 20px !important;
        font-weight: 700;
    }

    .FirstheadingDiv h1 {
        font-size: 20px !important;
        font-weight: 700;
    }

    .greyAboutBox {
        width: 90%;
        height: 300px;
        margin: auto;
        background-color: #D9D9D9;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
    }

    .greyAboutBox p {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }

    .valueFirst p {
        width: 100%;
        text-align: left;
        font-size: 12px;
        /* border: 2px solid red; */
    }

    .valueSecond p {
        width: 100%;
        text-align: right;
        /* border: 2px solid red; */
        font-size: 12px;
        float: none;
        clear: both;
    }

    .strengthHeading {
        font-size: 14px;
    }

    .aboutDiv {
        margin: 0px;
        display: flex !important;
        justify-content: space-between;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        /* border: 2px solid black; */
    }

    .aboutImgDiv {
        /* border: 2px solid black; */
        width: 280px !important;
        height: 240px !important;
        margin: 5px;
    }

    .IntegrityLine {
        color: black !important;
        font-size: 12px !important;
        padding: 0px 10px;
    }

    .aboutImgDiv h3 {
        font-size: 18px;
        color: #D00000;
        font-weight: 700;
    }

    .aboutImgDiv img {
        width: 35% !important;
    }

    #bHistory {
        display: none !important;
    }

    #bHistory2 {
        display: block !important;
    }

    .briefHistoryImgdiv {
        /* border: 2px solid black; */
        width: 90%;
        height: auto;
        margin-bottom: 0px;
        margin: auto;
    }

    .aboutDiv2 {
        /* border: 2px solid black; */
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr !important;
        /* justify-content: center;
        align-items: center; */
        margin: auto;
        place-items: center;
    }

    .aboutDiv {
        display: none !important;
    }
}

@media (min-width:375px)and (max-width:389px) {
    .aboutDiv {
        margin: 0px;
        /* display: flex !important; */
        justify-content: space-between;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 30px;
        align-items: center;
        /* border: 2px solid black; */
    }
}

@media (min-width:390px)and (max-width:430px) {
    .aboutDiv {
        margin: 0px;
        /* display: flex !important; */
        justify-content: space-between;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 30px;
        align-items: center;
        /* border: 2px solid black; */
    }
}

@media (min-width:400px)and (max-width:430px) {
    .aboutDiv {
        margin: 0px;
        /* display: flex !important; */
        justify-content: space-between;
        /* justify-content: center; */
        flex-wrap: wrap;
        gap: 35px;
        align-items: center;
        /* border: 2px solid black; */
    }
}

@media (min-width:431px)and (max-width:768px) {
    .psgDiv {
        padding: 50px 10px;
        width: 90%;
    }

    .psgDiv2 {
        /* border: 2px solid black; */
        padding: 130px 20px !important;
        width: 100%;
        height: 32vh;
        margin: auto;
        text-align: center;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: white;
    }

    .greyAboutBox {
        width: 85%;
    }

    .greyAboutBox p {
        font-size: 18px;
    }

    .valueSecond p {
        width: 100%;
        text-align: right;
        /* border: 2px solid red; */
        font-size: 16px;
        float: none;
        clear: both;
    }

    .strengthHeading {
        font-size: 20px;
    }

    .aboutDiv {
        margin: 10px;
        display: flex !important;
        justify-content: space-between;
        justify-content: center;
        flex-wrap: wrap;
        gap: 25px !important;
        align-items: center;
        /* border: 2px solid black; */
    }

    .hideFlip {
        display: none !important;
    }

    .showflip {
        display: block !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }


    .aboutImgDiv {
        width: 140px;
        height: 150px;
    }

    .aboutImgDiv img {
        /* border: 2px solid red; */
        width: 60%;
    }

    .IntegrityLine {
        color: black !important;
        font-size: 12px !important;
        padding: 0px 10px;
    }

    .aboutImgDiv h3 {
        font-size: 16px;
        color: #D00000;
        font-weight: 700;
    }

    .briefHistoryImgdiv {
        /* border: 2px solid black; */
        width: 100%;
        height: auto;
        margin-bottom: 50px;
    }

    .briefHistoryImgdiv img {
        width: 100%;
        height: 100%;
    }

}

@media(min-width:431px) and (max-width:650px) {
    .aboutImgDiv {
        width: 100px;
        height: 100px;
    }

    .aboutImgDiv img {
        /* border: 2px solid red; */
        width: 60%;
    }

    .IntegrityLine {
        color: black !important;
        font-size: 8px !important;
        padding: 0px 10px;
    }

    .aboutImgDiv h3 {
        font-size: 12px;
        color: #D00000;
        font-weight: 700;
    }

    .aboutImgDiv p {
        font-size: 13px;
    }

    .flipfrontPara {
        font-size: 20px !important;
    }

    .psgDiv2 {
        /* border: 2px solid black; */
        padding: 80px 20px !important;
        width: 100%;
        height: 40vh;
        margin: auto;
        text-align: center;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: white;
    }
}

@media(min-width:769px) and (max-width:1250px) {

    .aboutImgDiv img {
        /* border: 2px solid red; */
        width: 60%;
    }

    .IntegrityLine {
        color: black !important;
        font-size: 13px !important;
        padding: 0px 10px;
    }

    .aboutImgDiv h3 {
        font-size: 16px;
        color: #D00000;
        font-weight: 700;
    }

    .aboutImgDiv p {
        font-size: 16px;
    }

    .psgDiv {
        padding: 50px 10px;
        width: 90%;
    }

    .psgDiv2 {
        /* border: 2px solid black; */
        padding: 150px 20px !important;
        width: 100%;
        height: 32vh;
        margin: auto;
        text-align: center;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: white;
    }

    .strengthHeading {
        font-size: 22px;
    }

    .valueSecond p {
        width: 100%;
        text-align: right;
        /* border: 2px solid red; */
        font-size: 16px;
        float: none;
        clear: both;
    }

    .valueFirst p {
        width: 60%;
        text-align: left;
        /* border: 2px solid red; */
    }

    .greyAboutBox {
        width: 85%;
    }

    .greyAboutBox p {
        font-size: 20px;
    }

    .briefHistoryImgdiv {
        /* border: 2px solid black; */
        width: 100%;
        height: auto;
        margin-bottom: 50px;
    }

    .briefHistoryImgdiv img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width:1800px) {
    .FirstheadingDiv {
        background-color: #D00000;
        text-align: center;
        padding: 10px 0px;
        color: white;
        text-transform: uppercase;
        margin-top: 90px;
    }
}



@media (min-width: 601px) and (max-width: 1000px) {
    .FirstheadingDiv {
        background-color: #D00000;
        text-align: center;
        padding: 10px 0px;
        color: white;
        text-transform: uppercase;
        margin-top: 47px !important;
    }

    .headingDiv {
        background-color: #D00000;
        text-align: center;
        padding: 10px 0px;
        color: white;
        text-transform: uppercase;
        /* border: 2px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .aboutDiv {
        display: none !important;
    }

    .aboutDiv2 {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        row-gap: 30px;
        place-items: center;
    }

    .aboutImgDiv {
        width: 250px !important;
        height: 250px !important;
        /* border: 2px solid black; */
    }

    .career {
        margin-top: 46px !important;
        height: auto;
        display: flex;
        background-color: #F7F7F7;
        align-items: center;
        justify-content: space-between;
    }
}


@media (min-width: 1001px) and (max-width: 1300px) {
    .aboutImgDiv {
        width: 250px !important;
        height: 250px !important;
        /* border: 2px solid black; */
    }
}

@media (min-width: 1301px) and (max-width: 1405px) {
    .headingDiv {
        align-items: center;
        background-color: #d00000;
        color: #fff;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }
}