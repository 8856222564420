.topBglatest{
    /* position: fixed;
    top: 0;
    left: 0; */
    background-color: #DA1F24 !important;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 140px;
  }
  
  .topBglatestIcons{
    display: flex;
    gap: 10px;
  }
  .topBglatestAdd{
    display: flex;
  }
  
  #topBglatestAddemail{
    color: white !important;
    margin: 0px 10px 0px 0px;
  }
  #topBglatestAddcall{
    margin: 0px;
    color: white !important;
  }
  .topBglatestcircle{
    border: 2px solid white;
    border-radius: 50%;
    padding: 8px;
    background-color: #D00000;
    display: flex;
  justify-content: center;
  align-items: center;
  }

  @media (max-width:600px){
    .topBglatest{
      height: 0px;
      display: none !important;
    }
    /* .wpfloat .topBglatest{
      display: contents;
    } */
  }

  @media (min-width: 601px) and (max-width: 900px) {
    .topBglatest{
      height: 0px;
      display: none !important;
    }
  }