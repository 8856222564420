.papermaindiv{
    /* border: 2px solid black; */
    width: 85%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    place-items: center;
    row-gap: 50px;
}
.practicepaper{
    margin-top: 120px;
    margin-bottom: 50px;
}
.papersection{
    /* border: 2px solid red; */
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}
.papersection h1{
    font-size: 16px;
    color: black;
    font-weight: 700;
    text-align: center;
}
.papersection:hover{
    background-color: #DA1F24;
}
.papersection:hover .paperheading{
    color: white !important;
}
.paperanchor:hover{
    cursor: pointer;
}

@media(max-width:600px){
    .papermaindiv{
        /* border: 2px solid black; */
        margin-top: 20px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        place-items: center;
        row-gap: 50px;
        width: 100%;
    }
    .practicepaper{
        margin-top: 80px;
        margin-bottom: 50px;
    }
    .papersection{
        /* border: 2px solid red; */
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .papersection h1{
        font-size: 16px;
        color: black;
        font-weight: 700;
        text-align: center;
    }
    
}


@media (min-width: 601px) and (max-width: 1000px) {
    .papermaindiv{
        /* border: 2px solid black; */
        margin-top: 20px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center;
        row-gap: 50px;
        width: 100%;
    }
    .practicepaper{
        margin-top: 80px;
        margin-bottom: 50px;
    }
    .papersection{
        /* border: 2px solid red; */
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .papersection h1{
        font-size: 16px;
        color: black;
        font-weight: 700;
        text-align: center;
    }
}

@media (min-width: 1001px) and (max-width: 1200px) {
    .papermaindiv{
        /* border: 2px solid black; */
        margin-top: 20px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        place-items: center;
        row-gap: 50px;
        width: 100%;
    }
    .practicepaper{
        margin-top: 80px;
        margin-bottom: 50px;
    }
    .papersection{
        /* border: 2px solid red; */
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .papersection h1{
        font-size: 16px;
        color: black;
        font-weight: 700;
        text-align: center;
    }
}