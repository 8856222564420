.mainsubengdiv {
    background-color: #DA1F24;
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.mainsubengdivinner {
    width: 80%;
    margin: auto;
}


.mainsubengdivinner h2 {
    color: white;
    font-size: 60px;
    font-weight: 700;
    margin: 20px 0px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.mainsubengdivinner p {
    color: white;
    font-size: 30px;
    font-weight: 700;
    margin: 20px 0px;
}

.subengwhitediv {
    /* border: 2px solid black; */
    width: 80%;
    margin: auto;
    padding: 60px 0px;
}

.subengwhitediv h3 {
    color: #DA1F24;
    font-size: 35px;
    font-weight: 700 !important;
    margin: 20px 0px;
}

.subeng1line {
    color: #333333;
    font-size: 20px;
    font-weight: 700 !important;
    margin: 20px 0px;
}

.subengwhitediv p {
    font-size: 20px;
    margin: 20px 0px;
    color: #333333;
    text-align: justify !important;
}

.subengbluedivmain {
    background-color: #f6f7fd;
    padding: 30px 0px;
}

.subengbluediv {
    width: 80%;
    margin: auto;
}

.subengblueflex {
    display: flex;
    /* border: 2px solid black; */
    margin-bottom: 20px;
}

.subengblue1 {
    /* border: 2px solid black; */
    width: 45%;
    color: #DA1F24;
    font-size: 20px;
    font-weight: 700 !important;
}

.subengblue2 {
    /* border: 2px solid black; */
    width: 55%;
    color: black;
    font-size: 20px;
}

.subengbluediv h3 {
    color: #DA1F24;
    font-size: 30px;
    font-weight: 700 !important;
    margin: 20px 0px;
}

.subengblueflexother p {
    color: #DA1F24;
    font-size: 22px;
    font-weight: 700 !important;
}

.subli {
    color: black;
    font-size: 18px;
    margin-bottom: 20px;
    display: list-item !important;
}

.subli2 {
    color: black;
    font-size: 16px;
    display: list-item !important;
}




@media (max-width:600px) {
    .mainsubengdivinner h2 {
        color: white;
        font-size: 20px;
        font-weight: 700;
        margin: 20px 0px;
    }

    .mainsubengdivinner p {
        color: white;
        font-size: 16px;
        font-weight: 700;
        margin: 20px 0px;
    }

    .subengwhitediv h3 {
        color: #DA1F24;
        font-size: 22px;
        font-weight: 700 !important;
        margin: 20px 0px;
    }

    .subengwhitediv {
        /* border: 2px solid black; */
        width: 90%;
        margin: auto;
        padding: 20px 0px;
    }

    .subengwhitediv p {
        font-size: 16px;
        margin: 20px 0px;
        color: #333333;
    }

    .subengbluediv {
        width: 90%;
        margin: auto;
    }

    .subengbluediv h3 {
        color: #DA1F24;
        font-size: 25px;
        font-weight: 700 !important;
        margin: 10px 0px;
    }

    .subengblueflex {
        display: flex;
        flex-direction: column;
        /* border: 2px solid black; */
        margin-bottom: 20px;
    }

    .subengblue1 {
        /* border: 2px solid black; */
        width: 100%;
        color: #DA1F24;
        font-size: 20px;
        font-weight: 700 !important;
    }

    .subengblue2 {
        /* border: 2px solid black; */
        width: 100%;
        color: #333333;
        font-size: 16px;
    }

    .subli2 {
        color: black;
        font-size: 13px;
        margin-left: 20px;
    }

    .subli {
        color: black;
        font-size: 16px;
        margin-bottom: 20px;
        margin-left: 20px;
    }
}

@media (min-width:601px)and (max-width:1000px) {
    .mainsubengdiv {
        background-color: #DA1F24;
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .mainsubengdivinner h2 {
        color: white;
        font-size: 38px;
        font-weight: 700;
        margin: 20px 0px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
    .mainsubengdivinner p {
        color: white;
        font-size: 27px;
        font-weight: 700;
        margin: 20px 0px;
    }
    .subengwhitediv {
        /* border: 2px solid black; */
        width: 90%;
        margin: auto;
        padding: 60px 0px;
    }
    .subengwhitediv h3 {
        color: #DA1F24;
        font-size: 24px;
        font-weight: 700 !important;
        margin: 20px 0px;
    }
    .subengwhitediv p {
        font-size: 18px;
        margin: 20px 0px;
        color: #333333;
        text-align: justify !important;
    }
    .subengblue2 {
        /* border: 2px solid black; */
        width: 55%;
        color: black;
        font-size: 17px;
    }
    .subli {
        color: black;
        font-size: 17px;
        margin-bottom: 20px;
        display: list-item !important;
    }

}

@media (min-width:1001px)and (max-width:1396px) {
    .mainsubengdiv {
        background-color: #DA1F24;
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .mainsubengdivinner h2 {
        color: white;
        font-size: 40px;
        font-weight: 700;
        margin: 20px 0px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
    .mainsubengdivinner p {
        color: white;
        font-size: 29px;
        font-weight: 700;
        margin: 20px 0px;
    }
    .subengwhitediv {
        /* border: 2px solid black; */
        width: 90%;
        margin: auto;
        padding: 60px 0px;
    }
    .subengwhitediv h3 {
        color: #DA1F24;
        font-size: 28px;
        font-weight: 700 !important;
        margin: 20px 0px;
    }
    .subengwhitediv p {
        font-size: 18px;
        margin: 20px 0px;
        color: #333333;
        text-align: justify !important;
    }
    .subengblue2 {
        /* border: 2px solid black; */
        width: 55%;
        color: black;
        font-size: 17px;
    }
    .subli {
        color: black;
        font-size: 17px;
        margin-bottom: 20px;
        display: list-item !important;
    }
}